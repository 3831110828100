import { configureStore } from '@reduxjs/toolkit'
import {
    persistStore,
    persistReducer,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import { allReducers } from '../reducers'

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    whitelist: ['authSlice','GlobalSettingSlice'],
}

const persistedReducer = persistReducer(persistConfig, allReducers)

export const store = configureStore({
    reducer: persistedReducer,
})


export const persistor = persistStore(store)