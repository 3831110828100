import React from "react";
import {
    Typography,
    Avatar,
} from "@mui/material";
import Breadcrumb from "./BreadCrumb";
import PURCHASE_MANAGEMENT_COLORS from './../utills/constants';


export default function PageHeader({ children, icon, title }) {
    return (
        <>
            <div style={{
                background: "#eceaea", padding: '5px 30px 10px 30px',
                display: 'flex',
                flexDirection: 'column',
            }}>
                <div style={{ alignSelf: "flex-end" }}>
                    <Breadcrumb />
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{
                        minWidth: '15%', display: "flex", width: "fitContent", maxWidth: '30%',
                    }}
                        sx={{ mb: { xs: '2' } }}>

                        {Object.keys(PURCHASE_MANAGEMENT_COLORS).includes(`${title.toUpperCase()}`)
                            && <Avatar sx={{
                                width: 25, height: 25,
                                mr: 1,
                                mt: '2px',
                                fontSize: '15px',
                                fontWeight: "bold",
                                bgcolor: PURCHASE_MANAGEMENT_COLORS[title.toUpperCase()]
                            }}
                            >
                                {title.charAt(0).toUpperCase()}
                            </Avatar>}
                        <Typography color="text.primary" variant='h6'>
                            {title}
                        </Typography>
                    </div>
                    {children}
                </div>

            </div>


        </>
    )
}