import React, { useState, useEffect } from "react";
import UserList from "../components/UserList";
import UserSearch from "../components/UserSearch";

export default function UserContainer() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        setUsers([{
            "id": 1,
            "timeCreated": "2019-10-21T10:56:02.000+00:00",
            "timeUpdated": "2022-07-20T08:15:14.000+00:00",
            "active": true,
            "name": "abc",
            'email':"abc@gmail.com",
            "mobile": "8144511161",
        },
        {
            "id": 2,
            "timeCreated": "2019-10-21T10:56:46.000+00:00",
            "timeUpdated": "2021-04-07T08:24:18.000+00:00",
            "active": false,
            "name": "xyz",
            'email':"abc@gmail.com",
            "mobile": "8144533363",

        }])
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <>
            <UserSearch />
            <UserList
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                data={users}
            />
        </>
    )

}