import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar";
import { useLocation } from 'react-router-dom';
import { useNavigationHistory } from "../contexts/NavigationHistoryContext";

export default function Layout() {

    const location = useLocation();
    const { history, addToHistory, clearHistory } = useNavigationHistory();

    useEffect(() => {
        let pathname = location.pathname.split('/').filter((segment) => segment !== '')[0];
        if (!history.some(el => el.title === pathname)) {
            addToHistory({ url: location.pathname + location.search, title: pathname });
        }
        if (location.pathname === "/") {
            clearHistory();
        }
    }, [location]);

    return (
        <>
            <Navbar />
            <Outlet />
        </>
    )
}