import { ApiService } from "./NetworkHandler";
const baseUrl = '/api/v1/requisition'

export const RequisitionApi = {
    getCsv: function (warehouseId, deliveryDate) {
        return ApiService.sendRequest('get', `${baseUrl}/download-csv?warehouseId=${warehouseId}&deliveryDate=${deliveryDate}`);
    },
    updateRequisition: function (formData) {
        return ApiService.sendRequest('post', `${baseUrl}/create-or-update`, formData, { 'content-type': 'multipart/form-data' });
    },
    getDemands: function (warehouseId, skuId = '', deliveryDate, search = "") {
        let url = `${baseUrl}/get-all-by-warehouse?warehouseId=${warehouseId}&skuId=${skuId}&deliveryDate=${deliveryDate}`
        if (search) {
            url = `${url}&search=${search}`;
        }
        return ApiService.sendRequest('get', url)
    },
    deleteRequition: function (id) {
        return ApiService.sendRequest('post', `${baseUrl}/delete?id=${id}`);
    }
}