import React, { createContext, useContext, useState, useEffect } from 'react';

const NavigationHistoryContext = createContext();

export function NavigationHistoryProvider({ children }) {
    const [history, setHistory] = useState([]);

    // useEffect(() => {
    //     // Load navigation history from localStorage on component mount
    //     const savedHistory = JSON.parse(localStorage.getItem('navigationHistory')) || [];
    //     setHistory(savedHistory);
    // }, []);

    // useEffect(() => {
    //     // Save navigation history to localStorage whenever it changes
    //     localStorage.setItem('navigationHistory', JSON.stringify(history));
    // }, [history]);

    const addToHistory = (path) => {
        setHistory((prevHistory) => [...prevHistory, path]);
    };

    const clearHistory = () => {
        setHistory([]);
    };
    const popFromHistory = () => {
        const previousRoute = history.pop();
        setHistory([...history]);
    }

    return (
        <NavigationHistoryContext.Provider value={{ history, addToHistory, clearHistory, popFromHistory }}>
            {children}
        </NavigationHistoryContext.Provider>
    );
}


export const useNavigationHistory = () => useContext(NavigationHistoryContext);
