const STATUS_COLORS = {
    'NEW': 'primary',
    'DRAFT': 'primary',
    'PENDING': 'warning',
    'CONFIRMED': 'success',
    'SHIPPED': 'success',
    'RECEIVED': 'succces',
    'YET_TO_BE_APPROVED': 'warning',
    'PURCHASE_CONFIRMED': 'success',
    'APPROVED': 'success',
    'CANCELLED': 'error',
    'WAITING_FOR_CONFIRMATION': 'warning',
    'DISPATCHED': 'success',
    'SUBMITTED': 'info',
};
const PURCHASE_MANAGEMENT_COLORS = {
    'REQUISITIONS': '#009BF3',
    'QUOTES': '#E85F6F',
    'INDENTS': '#fa9917',
    'PURCHASE CONFIRMATIONS': '#009AAB',
    'SHIPMENTS': '#00731E',
};

export default {
    ...STATUS_COLORS,
    ...PURCHASE_MANAGEMENT_COLORS,
}