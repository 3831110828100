import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ShipmentApi } from "../../../api/ShipmentApi";
import ShipmentList from "../components/ShipmentList";
import {
    Typography,
    Backdrop,
    CircularProgress,
    Alert,
    Avatar,
} from '@mui/material';
import Breadcrumb from "../../../components/BreadCrumb";
import { useLocation } from "react-router-dom";
import ProductSelector from "../../../utills/ProductSelector";
import { debounce } from "lodash";
import PURCHASE_MANAGEMENT_COLORS from './../../../utills/constants';
import PageHeader from "../../../components/PageHeader";
import AlertComponent from "../../../utills/AlertComponent";

export default function ShipmentContainer() {
    const userWarehouse = useSelector(x => x.GlobalSettingSlice.userWarehouse);
    const deliveryDate = useSelector(x => x.GlobalSettingSlice.deliveryDate);
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    const [searchQuery, setSearchQuery] = useState(null);
    const [shipments, setShipments] = useState([]);
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const purchaseConfirmationId = queryParams.get('purchaseConfirmationId');

    useEffect(() => {
        fetchShipments();
    }, [userWarehouse, deliveryDate, purchaseConfirmationId]);

    const fetchShipments = debounce(async () => {
        try {
            setMessage(null);
            setLoading(true);
            const response = await ShipmentApi.getShipments(userWarehouse.id, deliveryDate, purchaseConfirmationId, searchQuery);
            const { data: { data = [] } } = response;
            setShipments(data);
            setLoading(false);
        }
        catch (error) {
            console.log(error);
            setLoading(false);
            let message = "Something went wrong! Please try again later";
            if (error && error.response && error.response.data && error.response.data.message) {
                message = error.response.data.message;
            }
            setMessage({ message: message, type: 'error' });
            setShipments([]);
        }
    }, 750);

    const clearMessage = () => {
        setMessage(null);
    }

    const handleChangeSearchInput = (value) => {
        console.log(value);
        setSearchQuery(value);
    }

    return (
        <>
            <PageHeader title='Shipments'>
                <div style={{ display: 'flex', margin: '0 auto' }}>

                    <ProductSelector
                        handleChangeSearchInput={handleChangeSearchInput}
                        searchQuery={searchQuery}
                        search={fetchShipments}
                    />
                </div>
            </PageHeader>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress />
            </Backdrop>
            {
                message && <div ><Alert severity="error" onClose={clearMessage} style={{ margin: '10px' }}>{message}</Alert>
                </div>
            }

            {message && <AlertComponent
                message={message.message}
                severity={message.type}
                onClose={clearMessage}
            />}

            <ShipmentList
                data={shipments} />
        </>
    )

}