import React from "react";
import {
    Typography,
    Button,
} from "@mui/material";

export default function UserSearch() {
    return (
        <div style={{
            display: 'flex', alignItems: 'center', background: "#f0f0f0", padding: '10px 30px', justifyContent: 'space-between'
        }}>
            <Typography variant="h5">Users</Typography>
            <Button variant="outlined">New User</Button>
        </ div>
    )
}