import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoggedIn: false,
    user: null,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        loginSuccess: (state, action) => {
            state.isLoggedIn = true;
            state.user = action.payload;
        },
        loginFailed: (state) => {
            state.isLoggedIn = false;
            state.user = null;
        },
        logout: (state) => {
            state.isLoggedIn = false;
            state.user = null;
        },
    },

});

export const { loginSuccess, loginFailed, logout } = authSlice.actions;

export default authSlice.reducer;