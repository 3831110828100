import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    FormHelperText,
    Modal,
    Select,
    TextField,
    Typography,
    MenuItem,
    Alert,
    Backdrop,
    CircularProgress
} from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IndentApi } from "../../../api/IndentApi";
import { isEmpty } from "lodash";

import { useSelector } from "react-redux";

import DeleteIcon from '@mui/icons-material/Delete';
import AlertComponent from "../../../utills/AlertComponent";

const style = {
    boxStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        // boxShadow: 24,
        p: 4,
        // borderRadius: '5px'
    },
    caption: {
        color: '#1976d2',
        fontWeight: '600',
        fontSize: 12,
    },
};

export default function ApproveOrRejectIndentModal(props) {
    const { isOpen, selectedData = {}, handleCloseModal, selectedQuote = null } = props;
    const [error, setError] = useState({});
    const [quantityApproved, setApprovedQty] = useState(0);
    const [message, setMessage] = useState(null);
    const [reason, setReason] = useState('');
    const [loading, setLoading] = useState(false);
    const { indentCancellationReason = {} } = useSelector(x => x.StatusSlice);
    const userWarehouse = useSelector(x => x.GlobalSettingSlice.userWarehouse);

    useEffect(() => {
        if (selectedData) {
            setApprovedQty(selectedData.quantityApproved);
            setReason(selectedData.reason || '');
        } else {
            setApprovedQty(0);
            setReason('');
        }
    }, [selectedData]);

    const onApprove = () => {
        setReason('');
        if (!quantityApproved) {
            setError({ quantityApproved: true, rejectionReason: false });
            return;
        } else {
            setError({});
            let postData = {
                "quoteId": selectedData.quoteId || selectedData.id,
                "requisitionId": selectedData.requisitionId,
                "skuId": selectedData.skuId,
                // "vendorId": selectedQuote.vendorId,
                "price": selectedData.price,
                "quantityDemanded": selectedData.quantityDemanded || 0,
                "quantityApproved": parseInt(quantityApproved),
                "status": 'APPROVED',
                "warehouse": userWarehouse.id,
                "reason": null,
            };
            onApproveorReject(postData);
        }
    }

    const onReject = () => {
        if (!reason) {
            setError({ quantityApproved: false, rejectionReason: true, });
            return;
        } else {
            setError({});
            let postData = {
                "quoteId": selectedData.quoteId || selectedData.id,
                "requisitionId": selectedData.requisitionId,
                "skuId": selectedData.skuId,
                // "vendorId": selectedQuote.vendorId,
                "price": selectedData.price,
                "quantityDemanded": selectedData.quantityDemanded || 0,
                "quantityApproved": 0,
                "status": "CANCELLED",
                "warehouse": userWarehouse.id,
                "reason": reason
            };
            onApproveorReject(postData);
        }
    }

    const setApprovedQuantity = (event) => {
        if (event.target.value < 0) return;
        // if (selectedQuote && selectedQuote.quantityApproved && event.target.value > selectedQuote.quantityApproved) {
        //     return;
        // }
        // if (selectedData && selectedData.quantityApproved && event.target.value > selectedData.quantityApproved) {
        //     return;
        // }
        setApprovedQty(event.target.value);
        setReason('');
    }


    const setRejectionReason = (event) => {
        setReason(event.target.value);
        setApprovedQty(0);
    }

    const onApproveorReject = async (postData) => {
        try {
            onClearMessage();
            setLoading(true);
            const response = await IndentApi.approveOrReject(postData);
            handleCloseModal();
            setLoading(false);
            props.onRefresh();
        } catch (error) {
            setLoading(false);
            let message = "Something went wrong! Please try again later";
            if (error && error.response && error.response.data && error.response.data.message) {
                message = error.response.data.message;
            }
            setMessage({ message: message, type: 'error' });
        }
    }

    const onClearMessage = () => {
        setMessage(null);
    }

    return (<>
        <Modal
            open={isOpen}
            onClose={(event, reason) => props.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style.boxStyle} >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography id="modal-modal-title" variant="body1">
                        {!isEmpty(selectedData) ? 'Edit Indent' : 'Create Indent'}
                    </Typography>
                    <CancelIcon color="error" onClick={handleCloseModal} />
                </div>

                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress />
                </Backdrop>
                {message && <AlertComponent
                    message={message.message}
                    severity={message.type}
                    onClose={onClearMessage}
                />}
                <div style={{ border: "1px solid LightGray", marginTop: "10px", padding: '1em', display: 'flex', flexWrap: 'wrap', }}>
                    <div style={{ flex: '60%', padding: '10px 0px', borderBottom: '1px solid LightGray', display: 'flex', flexDirection: 'column' }}>
                        <Typography style={style.caption}>Family Name</Typography>
                        <Typography variant="body1" >{selectedData && selectedData.familyName}</Typography>

                    </div>
                    <div style={{ flex: '40%', padding: '10px 0px', borderBottom: '1px solid LightGray' }}>
                        <Typography style={style.caption}>Size </Typography>
                        <Typography variant="body1">{selectedData && selectedData.size}</Typography>
                    </div>
                    <div style={{ flex: '1', padding: '10px 0px' }}>
                        <Typography style={style.caption}>Price</Typography>
                        <Typography variant="body1">{selectedData && selectedData.price}</Typography>
                    </div>
                    <div style={{ flex: '1', padding: '10px 0px' }}>
                        <Typography style={style.caption}>Quantity Demanded </Typography>
                        <Typography variant="body1">{selectedData ? selectedData.quantityDemanded : quantityApproved ? parseInt(quantityApproved) : 0}</Typography>
                    </div>
                    <div style={{ flex: '1', padding: '10px 0px' }}>
                        <Typography style={style.caption}>Quote Quantity </Typography>
                        <Typography variant="body1">{selectedData && (selectedData.quantity || selectedData.quoteQuantity)}</Typography>
                    </div>
                </div>
                <div style={{ border: "1px solid LightGray", marginTop: "10px", padding: '1em', display: 'flex', flexWrap: 'wrap', }}>
                    <div style={{ padding: '0px 0px', width: '100%' }}>
                        <Typography style={{ ...style.caption, color: error && error.quantityApproved ? 'red' : '#1976d2' }}>Quantity Approved</Typography>
                        <TextField
                            type="number"
                            variant="outlined"
                            size="small"
                            style={{ width: '100%', marginTop: '10px' }}
                            onKeyDown={(event) => {
                                if (event.key === '+' || event.key === '.' || event.key === '-') {
                                    event.preventDefault();
                                }
                            }}
                            value={quantityApproved}
                            onChange={setApprovedQuantity}
                            error={error && error.quantityApproved}
                            helperText={error && error.quantityApproved && 'Please enter Quantity Approved'}
                        />
                    </div>
                </div>
                <div style={{ border: "1px solid LightGray", marginTop: "10px", padding: '1em', display: 'flex', flexWrap: 'wrap', }}>
                    <div style={{ flex: '50%', padding: '0px 0px', }}>
                        <Typography style={{ ...style.caption, color: error && error.rejectionReason ? 'red' : '#1976d2' }}>Reason for Rejection</Typography>
                        <Select
                            value={reason}
                            onChange={setRejectionReason}
                            style={{
                                width: '100%',
                                height: '35px',
                                size: "small",
                                marginTop: '10px',
                                borderColor: (error && error.rejectionReason) && "red",
                            }}
                        >
                            {Object.keys(indentCancellationReason).map(key => {
                                return <MenuItem value={key}>{indentCancellationReason[key]}</MenuItem>
                            })}
                        </Select>
                        {error && error.rejectionReason && <FormHelperText error>Please enter reason for rejection</FormHelperText>}
                    </div>
                </div>

                <div style={{ margin: "10px 5px", display: 'flex', justifyContent: 'center' }}>
                    <Button variant="outlined" size="small" color="error" style={{ flex: '1 0 50%', marginRight: '5px', width: 'fullWidth' }}
                        onClick={() => onReject()}
                    >
                        <DeleteIcon /> Reject
                    </Button>
                    <Button variant="outlined" size="small" color="primary" style={{ flex: '1 0 50%', marginLeft: '5px', width: 'fullWidth' }}
                        onClick={() => onApprove()}
                    >
                        <CheckCircleIcon /> Approve
                    </Button>
                </div>
            </Box>
        </Modal >
    </>)

}