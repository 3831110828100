
import { ApiService } from "./NetworkHandler";
const baseURL = '/api/v1/purchase-confirmation';

export const PurchaseConfirmationApi = {
    getPurchaseConfirmations: function (warehouseId, deliveryDate, indentId, search = "") {
        let url = `${baseURL}/get-all-for-warehouse?warehouseId=${warehouseId}&deliveryDate=${deliveryDate}`;
        if (indentId) {
            url = `${url}&indentId=${indentId}`;
        }
        if (search) {
            url = `${url}&search=${search}`;
        }
        return ApiService.sendRequest('get', url);
    },
}