import { ApiService } from "./NetworkHandler";

const baseUrl = '/api/v1/shipments';

export const ShipmentApi = {
    getShipments: function (warehouseId, deliveryDate, purchaseConfirmationId = '', search = "") {
        let url = `${baseUrl}/get-for-warehouse?warehouseId=${warehouseId}&deliveryDate=${deliveryDate}`;
        if (purchaseConfirmationId) {
            url = `${url}&purchaseConfirmationId=${purchaseConfirmationId}`;
        }
        if (search) {
            url = `${url}&search=${search}`;
        }
        return ApiService.sendRequest('get', url);
    },
    getShipmentByPurchaseConfirmation: function (purchaseConfirmationId) {
        return ApiService.sendRequest('get', `${baseUrl}/get-by-purchase-confirmation?purchaseConfirmationId=${purchaseConfirmationId}`);
    },
}

