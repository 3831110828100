import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import { setUserWarehouse } from '../reducers/GlobalSettingSlice';
import { useNavigationHistory } from '../contexts/NavigationHistoryContext';
import { ThemeProvider } from '@mui/material';
import AuthTheme from '../modules/auth/AuthTheme';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    // boxShadow: 24,
    p: 3,
    // borderRadius: '5px'
};

export default function WarehouseSelectionModal(props) {
    const warehouses = props.warehouses;
    const userWarehouse = useSelector(x => x.GlobalSettingSlice.userWarehouse);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const dispatch = useDispatch();
    const { clearHistory } = useNavigationHistory();

    useEffect(() => {
        if (userWarehouse)
            setSelectedWarehouse(userWarehouse);
    }, [userWarehouse, props.open])

    const onselect = (event, option) => {
        setSelectedWarehouse(option);
    }

    const setWarehouse = () => {
        dispatch(setUserWarehouse(selectedWarehouse));
        clearHistory();
        props.handleClose();
    }

    return (
        <ThemeProvider theme={AuthTheme}>
            <Modal
                open={props.open}
                onClose={(event, reason) => props.parent !== 'login' && props.handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} >
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography id="modal-modal-title" variant="body1">
                            Select Warehouse
                        </Typography>
                        {props.parent !== 'login' && <CancelIcon color="error" onClick={props.handleClose} />}
                    </div>
                    {/* <Divider /> */}
                    <div style={{ margin: "10px 0px" }} >
                        <Autocomplete
                            disableClearable
                            options={warehouses.map(option => option)}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    margin="normal"
                                    fullWidth
                                    autoFocus
                                    size={"small"}
                                    label="Search Warehouse"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                    onChange={e => {
                                        e.preventDefault();
                                        if (!e.target.value) {
                                            setSelectedWarehouse(null);
                                        }
                                    }}
                                />

                            )}
                            onChange={onselect}
                            value={selectedWarehouse}
                        />
                    </div>
                    <div style={{ textAlign: 'right', marginTop: '5px' }}>
                        <Button size='small' variant='contained' onClick={() => setWarehouse()} disabled={!selectedWarehouse}>
                            Select
                        </Button>
                    </div>

                </Box>
            </Modal>
        </ThemeProvider>
    );
}
