import React from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AlertComponent(props) {
    const anchorOrigin = { vertical: 'top', horizontal: 'center' };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        props.onClose();
    };

    return (
        <Snackbar open={!!props.message} autoHideDuration={6000} onClose={handleClose}
            anchorOrigin={anchorOrigin}>
            <Alert onClose={handleClose} severity={props.severity}>
                {props.message}
            </Alert>
        </Snackbar>
    );
}