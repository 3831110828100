import axios from 'axios';
import Cookies from 'universal-cookie';
import CONFIG from '../config/config';
import history from '../components/CustomHistory';

export const api = axios.create({
    baseURL: `${CONFIG.BASE_URL}`,
    timeout: 600000 // time in miliseconds, 10 minutes
});

export const ApiService = {
    sendRequest: async (method, baseQuery, requestData = null, additionalHeaders = null) => {
        const HEADER_CONFIG = {
            headers: getHeaders(),
        };
        if (additionalHeaders) {

            HEADER_CONFIG.headers = {
                ...HEADER_CONFIG.headers,
                ...additionalHeaders,
            };
        }
        try {
            const response = await processRequest(method, baseQuery, requestData, HEADER_CONFIG);
            if (response.data && typeof response.data.data === 'string')
                console.log(response.data.data);
            else if (response.data && response.data.message && typeof response.data.message === 'string')
                console.log(response.data.message);
            return response;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.log("unauthorized");
                cookies.remove('access_token');
                handleUnauthorizedError();
            } else {
                if (!(method === 'get')) {
                    if (error.response && error.response.data) {
                        if (error.response.data.message) {
                            console.log(error.response.data.message);
                        }
                        else
                            console.log(error.response.data);
                    }

                    else
                        console.log('Something went wrong! Please try again later.');
                }
            }
            throw error;
        }
    }
}

const cookies = new Cookies();

const processRequest = (method, endPoint, requestData, headerConfig) => {
    if (method === 'get') {
        return api.get(endPoint, headerConfig);
    } if (method === 'post') {
        return api.post(endPoint, requestData, headerConfig);
    } if (method === 'put') {
        return api.put(endPoint, requestData, headerConfig);
    } if (method === 'patch') {
        return api.patch(endPoint, requestData, headerConfig);
    } if (method === 'delete') {
        return api.delete(endPoint, headerConfig);
    }
    throw new Error('Invalid method passed');
};

const getHeaders = () => {
    const token = cookies.get('access_token');
    const headers = {
        Authorization: token,
        'Content-Type': 'application/json',
    };
    return headers;
};

const handleUnauthorizedError = () => {
    console.log(cookies.get('access_token'));
    history.push("/login");
    window.location.reload();
};