import React from "react";
import { Popover, Typography, Button, Box } from "@mui/material";
import { styled } from '@mui/system';

const CustomButton = styled(Button)({
    width: '5px', // Set a custom width to fit content
    height: 'auto', // Set a custom height to fit content
});

export default function PopoverConfirm(props) {

    const { onCancel, onConfirm, title, anchorOrigin = {
        vertical: 'bottom',
        horizontal: 'left',
    },
        anchorEl = null, } = props
    return (

        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={onCancel}
            anchorOrigin={anchorOrigin}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <Box p={2} pb={6}>
                <Typography>
                    {title}
                </Typography>
                <div style={{ display: 'flex', float: 'right', marginTop: '2px' }}>
                    <CustomButton variant="outlined" color="error" onClick={onCancel} size="small" style={{ marginRight: '5px' }}>
                        No
                    </CustomButton>
                    <CustomButton variant="outlined" color="primary" onClick={onConfirm} size="small">
                        Yes
                    </CustomButton>
                </div>

            </Box>
        </Popover >
    )
}