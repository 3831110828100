import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    TableHead,
    Grid,
    Button,
    Tooltip,
    Modal,
    Box,
    Typography,
    TableSortLabel,
    Avatar,
} from "@mui/material";
import moment from "moment";
import DeleteIcon from '@mui/icons-material/Delete';
import { Cancel, Visibility } from "@mui/icons-material";
import CancelIcon from '@mui/icons-material/Cancel';
import IndentList from "../../indents/components/IndentList";
import { IndentApi } from "../../../api/IndentApi";
import { useSelector } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ApproveOrRejectIndentModal from "../../indents/components/ApproveorRejectModal";
import { useNavigate } from "react-router-dom";
import { QuotesApi } from "../../../api/QuotesApi";
import PURCHASE_MANAGEMENT_COLORS from './../../../utills/constants';
import PopoverConfirm from "../../../utills/PopoverConfirmation";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    // boxShadow: 24,
    p: 4,
    // borderRadius: '5px'
};

export default function QuoteList(props) {
    const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, data = [], parent = '', setLoading, setErrorMessage } = props;
    const [isOpen, openModal] = useState(false);
    const [indents, setIndents] = useState(null);
    const [isOpenModal, handleModalState] = useState(false);
    const [selectedQuote, setSelectedQuote] = useState(null);
    const { deliveryDate, userWarehouse } = useSelector(x => x.GlobalSettingSlice);
    const isDisabled = moment(deliveryDate).isBefore(moment().format('YYYY-MM-DD'));
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('skuId');
    const [sortedData, setSortedData] = useState(data);
    const [anchorEl, setAnchorEl] = useState(null);

    const navigate = useNavigate();

    const columns = [
        {
            id: 'image', label: 'Image',
            render: (value, props) => <div>
                <img style={{ height: '50px', maxWidth: '100%', width: '50px', objectFit: 'scale-down' }} src={value} />
            </div>
        },
        { id: 'familyName', label: 'Family Name', sortable: true, },
        { id: 'size', label: 'Size', sortable: true, },
        { id: 'price', label: 'Price', sortable: true, },
        { id: 'quantity', label: 'Quote Quantity', },
        {
            id: 'timeUpdated',
            label: 'Last Updated',
            sortable: true,
            format: (value) => moment(value).format('lll')
        },
        {
            id: 'timeUpdated',
            label: 'When',
            sortable: true,
            format: (value) => moment(value).fromNow()
        },
        { id: 'vendorName', label: 'Vendor Name', },
        { id: 'vendorLocation', label: 'Location', },
        // {
        //     id: "quantityDemanded",
        //     label: 'Required Quantity',
        // },
        // {
        //     id: "quantityApproved",
        //     label: 'Approved Quantity',
        // },
        // {
        //     id: "status",
        //     label: 'Status',
        // },
        {
            id: 'action',
            label: 'Action',
            render: (value, props) => <div style={{ display: 'flex' }}>
                {value.indentsCount > 0 ?
                    <Tooltip title="View Indents" arrow>
                        <Button
                            variant="outlined"
                            size="small"
                            // color="primary"
                            style={{ marginLeft: '10px', minWidth: '45px', fontSize: '14px', fontWeight: 'bolder', borderColor: PURCHASE_MANAGEMENT_COLORS['INDENTS'] }}
                            onClick={() => navigate(`/indents?quoteId=${value.id}`)}
                        >
                            <Avatar
                                sx={{ width: 22, height: 22, fontSize: '14px', fontWeight: 'bolder', bgcolor: PURCHASE_MANAGEMENT_COLORS['INDENTS'] }}
                            >
                                I
                            </Avatar>
                        </Button>
                    </Tooltip>
                    :
                    (!props.isDisabled &&
                        value.active
                    )
                    &&
                    < div style={{ display: "flex" }}>
                        <div>
                            <Tooltip title="Create Indent" arrow>
                                <span>
                                    <Button variant="outlined" size="small" style={{ minWidth: '45px', marginRight: '10px' }} onClick={() => createIndent(value)}
                                        disabled={props.isDisabled}
                                    >
                                        <AddIcon />
                                    </Button>
                                </span>
                            </Tooltip>
                        </div>
                        <div>
                            <Tooltip title="Cancel Quote" arrow>
                                <span>
                                    <Button variant="outlined" size="small" color="error" style={{ minWidth: '45px' }} onClick={handleOpenPopover}
                                        disabled={props.isDisabled}
                                    >
                                        <DeleteIcon />
                                    </Button>
                                </span>
                            </Tooltip>
                            <PopoverConfirm
                                onConfirm={() => { cancelQuote(value.id); handleClosePopover() }}
                                onCancel={handleClosePopover}
                                anchorEl={anchorEl}
                                title="Are you sure you want to delete this item?"
                            />
                        </div>
                    </div>
                }
            </div >
        }

        // {
        //     id: 'active',
        //     label: 'Status',
        //     render: (value) => <div style={{
        //         width: 'fit-content',
        //         color: value ? '#006644' : '#BF2600',
        //         backgroundColor: value ? '#E3FCEF' : '#FFEBE6',
        //         textTransform: 'uppercase',
        //         fontSize: '11px',
        //         fontWeight: '700',
        //         padding: '2px 4px 3px 4px',
        //         fontFamily: 'sans-serif'
        //     }} > {value ? 'Active' : 'Inactive'}</div >
        // },

    ];

    useEffect(() => {
        let quotelist = data;
        // if (parent !== 'requisition') {
        //     quotelist = data.map(el => {
        //         let indent = el.indents.find(ol => ol.status);
        //         console.log('indent', indent);
        //         if (indent) {
        //             return {
        //                 ...el,
        //                 'quantityDemanded': indent.quantityDemanded,
        //                 'quantityApproved': indent.quantityApproved,
        //                 'status': indent.status,
        //                 'purchaseOrderId': indent.purchaseOrderId,
        //                 indentId: indent.id,
        //                 quoteId: el.id,
        //                 quoteQuantity: el.quantity,
        //                 "requisitionId": indent.requisitionId,
        //             }
        //         } else {
        //             let indent = el.indents.find(ol => !ol.status);
        //             if (indent) {
        //                 return {
        //                     ...el,
        //                     quoteId: el.id,
        //                     quoteQuantity: el.quantity,
        //                     "requisitionId": indent.requisitionId,
        //                     "quantityDemanded": indent.quantityDemanded,
        //                     "quantityApproved": indent.quantityApproved,
        //                 }
        //             }

        //         }
        //         return {
        //             ...el,
        //             quoteId: el.id,
        //             quoteQuantity: el.quantity,
        //         };

        //     })
        // }
        setOrder('asc');
        setOrderBy('skuId');
        setSortedData(quotelist);
    }, [props.data])


    const handleOpenPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    let customColumns = parent === 'requisition' ? columns.filter(col => !['action', 'quantityDemanded', 'quantityApproved', 'status', 'timeUpdated'].includes(col.id)) : columns;

    const handleSort = (property) => () => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        // Sort the data based on the selected column and order
        const data = [...sortedData].sort((a, b) => {
            if (isAsc) {
                return a[property] > b[property] ? 1 : -1;
            } else {
                return b[property] > a[property] ? 1 : -1;
            }
        });
        setSortedData(data);
    };

    const createIndent = async (quote) => {
        setSelectedQuote(quote);
        handleModalState(true);
    }

    const handleCloseModal = () => {
        openModal(false);
        handleModalState(false);
        setSelectedQuote(null);
        setIndents(null);
    }

    const cancelQuote = async (quoteId) => {
        try {
            setLoading(true);
            setErrorMessage(null);
            const response = await QuotesApi.cancelQuote(quoteId);
            setLoading(false);
            setErrorMessage({ message: response.data.message, type: 'success' });
            onRefresh();
        } catch (error) {
            setLoading(false);
            let message = "Something went wrong! Please try again later";
            if (error && error.response && error.response.data) {
                message = error.response.data;
            }
            setErrorMessage({ message: message, type: 'error' });
        }
    }

    return (
        <>
            <Grid>
                <TableContainer sx={{ maxHeight: 500, width: '100%' }}>
                    <Table stickyHeader size="small" sx={{ p: '10px' }}>
                        <TableHead>
                            <TableRow>
                                {customColumns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        // align={column.align}
                                        style={{ color: '#7753a4', fontWeight: 'bold', }}
                                        sortDirection={orderBy === column.id ? order : false}
                                    >
                                        {column.sortable ? (<TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : 'asc'}
                                            onClick={handleSort(column.id)}
                                        >
                                            {column.label}

                                        </TableSortLabel>) : (
                                            column.label
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedData
                                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" key={row.id}>
                                            {customColumns.map((column) => {
                                                const value = column.id === 'action' ? row : row[column.id];
                                                // console.log(column.id, value);
                                                return (
                                                    <TableCell key={column.id}
                                                    // align={column.align}
                                                    >
                                                        {column.format && value
                                                            ? column.format(value)
                                                            : column.render
                                                                ? column.render(value, props)
                                                                : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {/* <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}

            <Modal
                open={isOpen}
                onClose={(event, reason) => handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} >
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography id="modal-modal-title" variant="body1">
                            Indents
                        </Typography>
                        <CancelIcon color="error" onClick={handleCloseModal} style={{ cursor: 'pointer' }} />
                    </div>
                    <Grid style={{ border: '1px solid', marginTop: '10px', marginBottom: "10px", padding: '0 1em' }}>
                        <IndentList
                            data={indents}
                            parent='quote'
                            selectedQuote={selectedQuote}
                            onRefresh={() => { createIndent(selectedQuote); props.onRefresh() }}
                            setLoading={(state) => props.setLoading(state)}
                            setErrorMessage={(message) => props.setErrorMessage(message)}
                            isDisabled={isDisabled}
                            closeParent={handleCloseModal}
                        />
                    </Grid>

                </Box>

            </Modal >

            <ApproveOrRejectIndentModal
                isOpen={isOpenModal}
                selectedData={selectedQuote}
                handleCloseModal={handleCloseModal}
                parent='quote'
                // selectedQuote={selectedQuote}
                onRefresh={props.onRefresh}
            />
        </>
    )

}