import React, { useEffect, useState } from "react";
import {
    Typography,
    Backdrop,
    CircularProgress,
    Alert,
    Modal,
    Box,
    Grid,
    Avatar,
} from '@mui/material';
import PurchaseConfirmationList from "../components/PurchaseConfirmationList";
import { PurchaseConfirmationApi } from "../../../api/PurchaseConfirmationApi";
import { useSelector } from "react-redux";
import ProductSelector from "../../../utills/ProductSelector";
import ShipmentList from "../../shipments/components/ShipmentList";
import { ShipmentApi } from "../../../api/ShipmentApi";
import CancelIcon from '@mui/icons-material/Cancel';
import Breadcrumb from "../../../components/BreadCrumb";
import { useLocation } from "react-router-dom";
import { debounce } from "lodash";
import PURCHASE_MANAGEMENT_COLORS from './../../../utills/constants';
import PageHeader from "../../../components/PageHeader";
import AlertComponent from "../../../utills/AlertComponent";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    // boxShadow: 24,
    p: 4,
    // borderRadius: '5px'
};

export default function PurchaseConfirmationContainer() {

    const [message, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const userWarehouse = useSelector(x => x.GlobalSettingSlice.userWarehouse);
    const deliveryDate = useSelector(x => x.GlobalSettingSlice.deliveryDate);
    const [filteredData, setFilterdData] = useState([]);
    const [shipmentData, setShipmentData] = useState([]);
    const [openShipment, setOpenShipment] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const location = useLocation();

    const [searchQuery, setSearchQuery] = useState('');

    // Create a new URLSearchParams object from the query string
    const queryParams = new URLSearchParams(location.search);

    // Access specific query parameters
    const indentId = queryParams.get('indentId');

    useEffect(() => {
        fetchPurchaseConfirmations();
    }, [deliveryDate, userWarehouse, indentId]);

    const clearMessage = () => {
        setErrorMessage(null);
    }

    const fetchPurchaseConfirmations = debounce(async () => {
        try {
            setErrorMessage(null);
            setLoading(true);
            const response = await PurchaseConfirmationApi.getPurchaseConfirmations(userWarehouse.id, deliveryDate, indentId, searchQuery);
            const { data: { data = [] } } = response;
            setData(data);
            let filteredData = data;
            if (selectedProduct) {
                filteredData = data.filter(el => el.skuId === option.id);
            }
            setFilterdData(filteredData);
            setLoading(false);
        }
        catch (error) {
            setLoading(false);
            let message = "Something went wrong! Please try again later";
            if (error && error.response && error.response.data && error.response.data.message) {
                message = error.response.data.message;
            }
            setErrorMessage({ message: message, type: 'error' });
            setData([]);
            setFilterdData([]);
        }
    }, 750);

    const handleChangeProduct = (event, option) => {
        setSelectedProduct(option);
        if (option) {
            let filteredData = data.filter(el => el.skuId === option.id)
            setFilterdData(filteredData);
        } else {
            setFilterdData(data);
        }
    }

    const onViewShipment = async (value) => {
        setSelectedData(value);
        try {
            const response = await ShipmentApi.getShipmentByPurchaseConfirmation(value.id)
            const { data: { data = {} } } = response;
            setShipmentData(data);
            setOpenShipment(true);
        } catch (error) {
            setShipmentData([]);
            setOpenShipment(true);
        }
    }

    const handleCloseModal = () => {
        setOpenShipment(false);
        setShipmentData([]);
        setSelectedData(null);
    }

    const handleChangeSearchInput = (value) => {
        console.log(value);
        setSearchQuery(value);
    }

    return (
        <>
            <PageHeader title='Purchase Confirmations'>
                <div style={{ display: 'flex', margin: '0 auto' }}>
                    <ProductSelector
                        handleChangeSearchInput={handleChangeSearchInput}
                        searchQuery={searchQuery}
                        search={fetchPurchaseConfirmations}
                    />
                </div>
            </PageHeader>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress />
            </Backdrop>

            {message && <AlertComponent
                message={message.message}
                severity={message.type}
                onClose={clearMessage}
            />}

            {filteredData && filteredData.length > 0 &&
                <PurchaseConfirmationList
                    data={filteredData}
                    onViewShipment={onViewShipment}
                />}
            <Modal
                open={openShipment}
                onClose={(event, reason) => handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} >
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography id="modal-modal-title" variant="body1">
                            Shipments
                        </Typography>
                        <CancelIcon color="error" onClick={handleCloseModal} />
                    </div>
                    <Grid style={{ border: '1px solid', marginTop: '10px', marginBottom: "10px", padding: '0 1em' }}>
                        {shipmentData && <ShipmentList
                            data={shipmentData}
                            parent='purchase-confirmation'
                            selectedData={selectedData || {}}
                        />}
                    </Grid>

                </Box>

            </Modal >
        </>
    )
}