import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TableHead,
    Grid,
    Chip,
    TableSortLabel,
} from "@mui/material";
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import moment from "moment";
import DeleteIcon from '@mui/icons-material/Delete';
import { Cancel } from "@mui/icons-material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { isEmpty, values } from "lodash";
import { useSelector } from "react-redux";
import STATUS_COLOURS from "./../../../utills/constants";

const childColumn = [
    { id: 'image', label: 'Image' },
    { id: 'productName', label: 'Family Name' },
    { id: 'size', label: 'Size' },
    { id: 'quantity', label: 'Quantity' },
    { id: 'unitPrice', label: 'Unit Price' },
];

function Row(props) {
    const { row = [], parentColumn } = props;
    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            <TableRow hover role="checkbox" tabIndex={-1} key={row.id} onClick={() => setOpen(!open)}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                {parentColumn.map(column => {
                    const value = column.id === 'action' ? row : row[column.id];
                    return (
                        <TableCell key={column.id}
                        // align={column.align}
                        >
                            {column.format && value
                                ? column.format(value)
                                : column.render
                                    ? column.render(value, props)
                                    : value ? value : '-'}
                        </TableCell>
                    );
                })}
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, margin: 2, border: open ? "1px solid black" : "" }} colSpan={9}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {/* <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                History
                            </Typography> */}
                        <Table size="small" aria-label="purchases" sx={{ border: '2px sold black' }}>
                            <TableHead>
                                {childColumn.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={'right'}
                                        style={{ color: '#7753a4', fontWeight: 'bold', fontSize: '12px' }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableHead>
                            <TableBody>
                                {row.shipmentLineItems.map((item) => {
                                    return (<TableRow>
                                        {childColumn.map((col) => (
                                            // const value = col.id === 'action' ? item : item[col.id]; <TableRow key={item.id}> */}
                                            <TableCell component="th" scope="row" align={'right'}>
                                                {col.id !== 'image' ? item[col.id]
                                                    :
                                                    <div>
                                                        <img style={{ height: '50px', maxWidth: '100%', width: '50px', objectFit: 'scale-down' }} src={item[col.id]} />
                                                    </div>}
                                            </TableCell>
                                        ))}
                                    </TableRow>)
                                }
                                )}
                            </TableBody>
                        </Table>
                        {/* </Box> */}
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
        //     {
        //         childColumn.map((col) => {
        //             const value = col.id === 'action' ? item : item[col.id];
        //             // console.log(value, col.id, item[col.id]);
        //             // return { value };
        //             return (
        //                 <TableCell key={col.id}>
        //                     {value}
        //                 </TableCell>
        //             );
        //         })

        //     }
        // </TableRow>

    );
}

export default function ShipmentList(props) {
    const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, selectedData } = props;
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [sortedData, setSortedData] = useState([]);
    const { shipmentStatuses } = useSelector(x => x.StatusSlice);
    const parentColumn = [
        { id: 'shippedFrom', label: 'Shipped From', sortable: true, },
        { id: 'shippedTo', label: 'Shipped To', sortable: true, },
        { id: 'totalQuantity', label: 'Quantity', sortable: true, },
        { id: 'totalValue', label: 'Price', sortable: true, },
        { id: 'vehicleNumber', label: 'vehicle Number', sortable: true, },
        { id: 'vendorId', label: 'Vendor', sortable: true, },
        {
            id: 'status',
            label: 'Status',
            sortable: true,
            render: (value) => <Chip
                size="small"
                color={STATUS_COLOURS[value]}
                label={shipmentStatuses[value].toUpperCase() || value}
                style={{ fontSize: '10px', fontWeight: 'bold' }}
                variant="outlined"
            />
        },
        {
            id: 'dispatchDate',
            label: 'Dispatched On',
            sortable: true,
            sortable: true,
            format: (value) => moment(value).format('lll'),
        },
    ];


    useEffect(() => {
        let data = props.data || [];
        if (!isEmpty(selectedData)) {
            data.forEach((row, index) => {
                data[index].shipmentLineItems = row.shipmentLineItems.map(item => {
                    return (
                        {
                            ...item,
                            size: selectedData.size,
                            image: selectedData.image,
                        }
                    )
                })
            })
        }
        setSortedData(data);
        setOrder('asc');
        setOrderBy('');
    }, [props]);

    const handleSort = (property) => () => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        // Sort the data based on the selected column and order
        const sortData = [...sortedData].sort((a, b) => {
            if (isAsc) {
                return a[property] > b[property] ? 1 : -1;
            } else {
                return b[property] > a[property] ? 1 : -1;
            }
        });
        setSortedData(sortData);
    };


    return (
        <>
            <Grid>
                <TableContainer sx={{ maxHeight: 500, width: '100%' }}>

                    <Table stickyHeader size="small" sx={{ p: '10px' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                {parentColumn.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        // align={column.align}
                                        style={{ color: '#7753a4', fontWeight: 'bold' }}
                                        sortDirection={orderBy === column.id ? order : false}
                                    >
                                        {column.sortable ? (<TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : 'asc'}
                                            onClick={handleSort(column.id)}
                                        >
                                            {column.label}

                                        </TableSortLabel>) : (
                                            column.label
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {sortedData.map((row) => (
                                <Row key={row.id} row={row} parentColumn={parentColumn} />
                            ))}
                        </TableBody>
                    </Table>
                    {sortedData.length === 0 && <div style={{
                        margin: '32px 0',
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '0.75em',
                        textTransform: 'uppercase',
                    }}>
                        No Shipments created  </div>}

                </TableContainer>
            </Grid >
        </>
    )
}