import React, { useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    TableHead,
    Grid,
    Button,
    Tooltip,
    TableSortLabel,
    Avatar,
    Chip,
} from "@mui/material";
import moment from "moment";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Cancel } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import STATUS_COLOURS from "./../../../utills/constants";
import PURCHASE_MANAGEMENT_COLORS from "./../../../utills/constants";

export default function PurchaseConfirmationList(props) {
    const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, data = [] } = props;
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('skuId');
    const [sortedData, setSortedData] = useState(data);
    const navigate = useNavigate();
    const { poCancellationReason, purchaseOrderStatus } = useSelector(x => x.StatusSlice);

    const columns = [
        {
            id: "image", label: 'Image',
            render: (value, props) => <div>
                <img style={{ height: '50px', maxWidth: '100%', width: '50px', objectFit: 'scale-down' }} src={value} />
            </div>
        },
        { id: "familyName", label: 'Family Name', sortable: true, },
        { id: "size", label: 'Size', sortable: true, },
        { id: "quantityDemanded", label: 'Required Quantity' },
        { id: "vendorName", label: "Vendor Name" },
        { id: "vendorLocation", label: "Location" },
        { id: "price", label: 'Price', },
        { id: "quantityApproved", label: 'Approved Quantity' },
        { id: "confirmedQuantity", label: ' Confirmed Quantity' },
        {
            id: 'timeUpdated',
            label: 'Last Updated',
            sortable: true,
            format: (value) => moment(value).format('lll')
        },
        {
            id: 'timeUpdated',
            label: 'When',
            sortable: true,
            format: (value) => moment(value).fromNow()
        },
        {
            id: "status",
            label: 'Status',
            render: (value) => <Chip
                size="small"
                color={STATUS_COLOURS[value]}
                label={(purchaseOrderStatus[value] && purchaseOrderStatus[value].toUpperCase()) || value}
                style={{ fontSize: '10px', fontWeight: 'bold' }}
                variant="outlined"
            />
        },
        {
            id: "reason",
            label: 'Reason',
            format: (value) => poCancellationReason[value] || value || '-'
        },
        {
            id: 'action',
            label: 'Actions',
            render: (value, props) =>
                < div >
                    {value.shipmentsCount > 0 && <Tooltip title="View Shipment" arrow>
                        <Button
                            variant="outlined"
                            size="small"
                            // color="primary"
                            style={{ marginLeft: '10px', minWidth: '45px', fontSize: '14px', fontWeight: 'bolder', borderColor: PURCHASE_MANAGEMENT_COLORS['SHIPMENTS'] }}
                            onClick={() => navigate(`/shipments?purchaseConfirmationId=${value.id}`)}
                        >
                            <Avatar
                                sx={{ width: 22, height: 22, fontSize: '14px', fontWeight: 'bolder', bgcolor: PURCHASE_MANAGEMENT_COLORS['SHIPMENTS'] }}
                            >
                                S
                            </Avatar>
                        </Button>
                    </Tooltip>}
                </div >
        }

    ];


    const handleSort = (property) => () => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        // Sort the data based on the selected column and order
        const sortedData = [...data].sort((a, b) => {
            if (isAsc) {
                return a[property] > b[property] ? 1 : -1;
            } else {
                return b[property] > a[property] ? 1 : -1;
            }
        });
        setSortedData(sortedData);
    };

    return (
        <>
            <Grid>
                <TableContainer sx={{ maxHeight: 500, width: '100%' }}>
                    <Table stickyHeader size="small" sx={{ p: '10px' }}>
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        // align={column.align}
                                        style={{ color: '#7753a4', fontWeight: 'bold' }}
                                        sortDirection={orderBy === column.id ? order : false}
                                    >
                                        {column.sortable ? (<TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : 'asc'}
                                            onClick={handleSort(column.id)}
                                        >
                                            {column.label}

                                        </TableSortLabel>) : (
                                            column.label
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedData
                                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                            {columns.map((column) => {
                                                const value = column.id === 'action' ? row : row[column.id];
                                                return (
                                                    <TableCell key={column.id}
                                                    // align={column.align}
                                                    >
                                                        {column.format && value
                                                            ? column.format(value)
                                                            : column.render
                                                                ? column.render(value, props)
                                                                : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {/* <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
        </>
    )

}