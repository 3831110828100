import { ApiService } from "./NetworkHandler";
const baseURL = '/api/v1/quotes'

export const QuotesApi = {
    getQuotes: function (warehouseId, deliveryDate, requisitionId = '', search = "") {
        let url = `${baseURL}/get-all-for-warehouse?warehouseId=${warehouseId}&deliveryDate=${deliveryDate}`
        if (requisitionId) {
            url = `${url}&requisitionId=${requisitionId}`;
        }
        if (search) {
            url = `${url}&search=${search}`;
        }
        return ApiService.sendRequest('get', url);
    },
    cancelQuote: function (quoteId) {
        return ApiService.sendRequest('post', `${baseURL}/close?id=${quoteId} `);
    }
}