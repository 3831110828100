import React, { useEffect, useState } from "react"
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    TableHead,
    Grid,
    Button,
    Chip,
    TableSortLabel,
} from "@mui/material";
import moment from "moment";
import EditIcon from '@mui/icons-material/Edit';

const columns = [
    { id: 'name', label: 'Name', sortable: true },
    {
        id: 'mobile',
        label: 'Mobile',
        sortable: true,
    },
    {
        id: 'vendor',
        label: 'Vendor Name',
        sortable: true,
        format: (value) => value.name
    },
    {
        id: 'harbours',
        label: 'Harbours',
        render: (value) => <div>
            {value.map(el => {
                return (<Chip
                    key={el.id}
                    size="small"
                    color={'primary'}
                    label={el.name.toUpperCase()}
                    style={{ fontSize: '10px', fontWeight: 'bold', marginRight: '5px' }}
                    variant="outlined"
                />)
            }
            )}  </div>
    }
];

export default function AgentList(props) {
    const { data } = props;
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('skuId');
    const [sortedData, setSortedData] = useState(data);

    useEffect(() => {
        setSortedData(data);
        setOrder('asc');
        setOrderBy('skuId');
    }, [props]);

    const handleSort = (property) => () => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        // Sort the data based on the selected column and order
        const sortData = [...sortedData].sort((a, b) => {
            if (isAsc) {
                return a[property] > b[property] ? 1 : -1;
            } else {
                return b[property] > a[property] ? 1 : -1;
            }
        });
        setSortedData(sortData);

    };

    return (
        <>
            <Grid>
                <TableContainer sx={{ maxHeight: 500, width: '100%' }}>
                    <Table stickyHeader size="medium" sx={{ p: '10px' }}>

                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        // align={column.align}
                                        style={{ color: '#7753a4', fontWeight: 'bold', }}
                                        sortDirection={orderBy === column.id ? order : false}
                                    >
                                        {column.sortable ? (<TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : 'asc'}
                                            onClick={handleSort(column.id)}
                                        >
                                            {column.label}

                                        </TableSortLabel>) : (
                                            column.label
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedData
                                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                            {columns.map((column) => {
                                                const value = column.id === 'action' ? row : row[column.id];
                                                return (
                                                    <TableCell key={column.id}
                                                    // align={column.align}
                                                    >
                                                        {column.format && value
                                                            ? column.format(value)
                                                            : column.render
                                                                ? column.render(value)
                                                                : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>


            </Grid>

            {/* <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
        </>

    );
}