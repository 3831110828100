import React, { useEffect, useState } from "react";
import {
    Typography,
    Button,
    TextField,
    InputAdornment,
} from "@mui/material";
import { useSelector } from "react-redux";
import moment from "moment";
import AgentList from "../components/AgentList";
import { VendorApi } from "../../../api/VendorApi";
import Breadcrumb from "../../../components/BreadCrumb";
import PageHeader from "../../../components/PageHeader";
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from "lodash";

export default function AgentContainer() {
    const [vendors, setVendors] = useState([]);
    const [agents, setAgents] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const userWarehouse = useSelector(x => x.GlobalSettingSlice.userWarehouse);
    const deliveryDate = useSelector(x => x.GlobalSettingSlice.deliveryDate);
    const isDisabled = moment(deliveryDate).isBefore(moment().format('YYYY-MM-DD'));
    const [filteredData, setFilteredData] = useState([]);

    const [searchQuery, setSearchQuery] = useState(null);

    useEffect(() => {
        fetchVendors();
    }, [userWarehouse]);

    useEffect(() => {
        search();
    }, [searchQuery, agents]);

    const search = () => {
        if (searchQuery) {
            let data = agents.filter(el => el.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                el.mobile.toLowerCase().includes(searchQuery.toLowerCase()) ||
                el.vendor.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                el.vendor.mobile.toLowerCase().includes(searchQuery.toLowerCase()))
            setFilteredData(data);
        } else {
            setFilteredData(agents);
        }
    };

    const fetchVendors = async () => {
        try {
            const response = await VendorApi.getVendors(userWarehouse.id);
            const { data: { data = [] } } = response;
            setVendors(data);
            let agent = [];
            data.forEach(element => {
                let agents = element.agents.map(el => {
                    return {
                        ...el,
                        'vendor': { name: element.name, mobile: element.mobile }
                    }
                })
                agent = agent.concat(agents);
            });
            setAgents(agent);
            search();
        } catch (error) {
            setVendors([]);
            setAgents([]);
            setFilteredData([]);
        }
    }

    const handleChangeSearchInput = (value) => {
        setSearchQuery(value);
    }

    return (
        <>
            <PageHeader title="Agents">
                <TextField
                    label="Search"
                    onChange={(e) => { handleChangeSearchInput(e.target.value) }}
                    style={{ margin: 'auto', width: '500px' }}
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </PageHeader>
            <AgentList
                data={filteredData}

            />
        </>
    )
}