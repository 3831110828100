import React, { useEffect, useState } from "react";
import {
    Typography, TextField, Alert, Backdrop, CircularProgress, Button,
    Avatar,
} from "@mui/material";
import { IndentApi } from "../../../api/IndentApi";
import IndentList from "../components/IndentList";
import ApproveOrRejectIndentModal from "../components/ApproveorRejectModal";
import { useSelector } from "react-redux";
import ProductSelector from "../../../utills/ProductSelector";
import moment from "moment";
import Breadcrumb from "../../../components/BreadCrumb";
import { useLocation } from "react-router-dom";
import { debounce } from "lodash";
import PURCHASE_MANAGEMENT_COLORS from './../../../utills/constants';
import PageHeader from "../../../components/PageHeader";
import AlertComponent from "../../../utills/AlertComponent";

export default function IndentContainer() {
    const [indents, setIndents] = useState([]);
    const [filteredIndents, setFilterdIndents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setErrorMessage] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const userWarehouse = useSelector(x => x.GlobalSettingSlice.userWarehouse);
    const deliveryDate = useSelector(x => x.GlobalSettingSlice.deliveryDate);
    const isDisabled = moment(deliveryDate).isBefore(moment().format('YYYY-MM-DD'));
    const location = useLocation();
    const [isOpenModal, handleModalState] = useState(false);
    const [selectedData, setSelected] = useState(null);

    const [searchQuery, setSearchQuery] = useState(null);
    // Create a new URLSearchParams object from the query string
    const queryParams = new URLSearchParams(location.search);

    // Access specific query parameters
    const quoteId = queryParams.get('quoteId');

    useEffect(() => {
        getIndents();
    }, [deliveryDate, userWarehouse, quoteId]);

    const getIndents = debounce(async () => {
        try {
            setLoading(true);
            const response = await IndentApi.getIndents(userWarehouse.id, deliveryDate, quoteId, searchQuery);
            const { data: { data = [] } } = response;
            setIndents(data);
            let filteredIndents = data;
            if (selectedProduct) {
                filteredIndents = data.filter(indent => indent.skuId === option.id);
            }
            setFilterdIndents(filteredIndents);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setIndents([]);
            setFilterdIndents([]);
            let message = "Something went wrong! Please try again later";
            if (error && error.response && error.response.data && error.response.data.message) {
                message = error.response.data.message;
            }
            setErrorMessage({ message: message, type: "error" });
        }
    }, 750);

    const onRefresh = () => {
        setSelected(null);
        getIndents();
    }



    const handleChangeProduct = (event, option) => {
        setSelectedProduct(option);
        if (option) {
            let data = indents.filter(indent => indent.skuId === option.id)
            setFilterdIndents(data);
        } else {
            setFilterdIndents(indents);
        }
    }

    const onClearMessage = () => {
        setErrorMessage(null);
    }

    const handleChangeSearchInput = (value) => {
        console.log(value);
        setSearchQuery(value);
    }

    const handleCloseModal = () => {
        handleModalState(false);
        setSelected(null);
    }

    const createIndent = () => {
        setSelected({
            quoteId: quoteId,
            requisitionId: indents[0].requisitionId,
            skuId: indents[0].skuId,
            price: indents[0].price,
            quantityDemanded: indents[0].quantityDemanded || 0,
            familyName: indents[0].familyName,
            image: indents[0].image,
            size: indents[0].size,
            quoteQuantity: indents[0].quoteQuantity,
        });
        handleModalState(true);
    }

    return (
        <>
            <PageHeader title='Indents'>
                <div style={{ display: 'flex', margin: '0 auto', justifyContent: 'space-between' }}>
                    <ProductSelector
                        handleChangeSearchInput={handleChangeSearchInput}
                        searchQuery={searchQuery}
                        search={getIndents}
                    />
                    {quoteId && indents && !indents.some(el => el.status != "CANCELLED") && <Button variant="outlined" type="primary" onClick={createIndent} style={{ marginLeft: '30px' }}>Create Indent</Button>}
                </div>
            </PageHeader>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress />
            </Backdrop>

            {message && <AlertComponent
                message={message.message}
                severity={message.type}
                onClose={onClearMessage}
            />}

            <IndentList
                data={filteredIndents}
                onRefresh={getIndents}
                setLoading={(state) => setLoading(state)}
                setErrorMessage={(message) => setErrorMessage(message)}
                isDisabled={isDisabled}
            />
            <ApproveOrRejectIndentModal
                isOpen={isOpenModal}
                selectedData={selectedData || {}}
                handleCloseModal={handleCloseModal}
                parent='indents'
                // selectedQuote={selectedQuote}
                onRefresh={getIndents}
            />
        </>
    )
}