import React, { useEffect, useState } from "react";
import {
    Table,
    Button,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Grid,
    Tooltip,
    Fab,
    Avatar,
    TableSortLabel,
    Chip,
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import ApproveOrRejectIndentModal from "./ApproveorRejectModal";
import AddIcon from '@mui/icons-material/Add';
import { Cancel, Delete } from "@mui/icons-material";
import { IndentApi } from "../../../api/IndentApi";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import STATUS_COLOURS from "./../../../utills/constants";
import PURCHASE_MANAGEMENT_COLORS from "./../../../utills/constants";
import PopoverConfirm from "../../../utills/PopoverConfirmation";

export default function IndentList(props) {
    const { data, selectedQuote = null, onRefresh, setLoading, setErrorMessage } = props;
    const [isOpenModal, handleModalState] = useState(false);
    const [selectedData, setSelected] = useState(null);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('skuId');
    const [sortedData, setSortedData] = useState(data);
    const navigate = useNavigate();
    const { indentCancellationReason, indentStatuses } = useSelector(x => x.StatusSlice);
    const [anchorEl, setAnchorEl] = useState(null);

    const columns = [
        {
            id: "image", label: 'Image',
            render: (value, props) => <div>
                <img style={{ height: '50px', maxWidth: '100%', width: '50px', objectFit: 'scale-down' }} src={value} />
            </div>
        },
        { id: "familyName", label: 'Family Name', sortable: true, },
        { id: "size", label: 'Size', sortable: true, },
        { id: "quantityDemanded", label: 'Required Quantity' },
        { id: "vendorName", label: "Vendor Name" },
        { id: "vendorLocation", label: "Location" },
        { id: "price", label: 'Price' },
        { id: "quantityApproved", label: 'Approved Quantity' },
        {
            id: 'timeUpdated',
            label: 'Last Updated',
            sortable: true,
            format: (value) => moment(value).format('lll')
        },
        {
            id: 'timeUpdated',
            label: 'When',
            sortable: true,
            format: (value) => moment(value).fromNow()
        },
        {
            id: "status",
            label: 'Status',
            render: (value) => <Chip
                size="small"
                color={STATUS_COLOURS[value]}
                label={(indentStatuses[value] && indentStatuses[value].toUpperCase()) || value || 'YET TO BE APPROVED'}
                style={{ fontSize: '10px', fontWeight: 'bold' }}
                variant="outlined"
            />
        },
        {
            id: "reason",
            label: 'Reason',
            format: (value) => indentCancellationReason[value] || value
        },
        {
            id: 'action',
            label: 'Actions',
            render: (value, props) => value.purchaseOrderId ?
                <Tooltip title="View Purchase Confirmations" arrow>
                    <Button
                        variant="outlined"
                        size="small"
                        // color="primary"
                        style={{ marginLeft: '10px', minWidth: '45px', fontSize: '14px', fontWeight: 'bolder', borderColor: PURCHASE_MANAGEMENT_COLORS['PURCHASE CONFIRMATIONS'] }}
                        onClick={() => navigate(`/purchase-confirmations?indentId=${value.id}`)}
                    >
                        <Avatar
                            sx={{ width: 22, height: 22, fontSize: '14px', fontWeight: 'bolder', bgcolor: PURCHASE_MANAGEMENT_COLORS['PURCHASE CONFIRMATIONS'] }}
                        >
                            P
                        </Avatar>
                    </Button>
                </Tooltip>
                :
                < div style={{ display: 'flex' }}>
                    <Tooltip title="Edit Indent" arrow>
                        <span>
                            <Button variant="outlined" size="small" color="primary" style={{ minWidth: '45px', marginRight: '10px' }} onClick={() => onEdit(value)}
                                disabled={(value.status != 'APPROVED' || value.purchaseOrderId || props.isDisabled)}>
                                <EditIcon />
                            </Button>
                        </span>
                    </Tooltip>

                    <Tooltip title="Cancel Indent" arrow>
                        <span>
                            <Button variant="outlined" size="small" color="error" style={{ minWidth: '45px' }} onClick={handleOpenPopover}
                                disabled={(value.status != 'APPROVED' || value.purchaseOrderId || props.isDisabled)}
                            >
                                <Delete />
                            </Button>
                        </span>
                    </Tooltip>
                    <PopoverConfirm
                        onConfirm={() => { onCloseIndent(value.id); handleClosePopover() }}
                        onCancel={handleClosePopover}
                        anchorEl={anchorEl}
                        title="Are you sure you want to delete this item?"
                    />
                </div >
        }
    ];

    useEffect(() => {
        let indents = data;
        setSortedData(indents);
    }, [props.data])

    const onEdit = (value = null) => {
        setSelected(value);
        handleModalState(true);
    }

    const handleCloseModal = () => {
        handleModalState(false);
        setSelected(null);
    }

    const onCloseIndent = async (indentId) => {
        try {
            setLoading(true);
            setErrorMessage(null);
            const response = await IndentApi.closeIndent(indentId);
            setLoading(false);
            setErrorMessage({ message: response.data.message, type: 'success' });
            onRefresh();
        } catch (error) {
            setLoading(false);
            let message = "Something went wrong! Please try again later";
            if (error && error.response && error.response.data) {
                message = error.response.data;
            }
            setErrorMessage({ message: message, type: 'error' });
        }
    }

    const handleSort = (property) => () => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        // Sort the data based on the selected column and order
        const data = [...sortedData].sort((a, b) => {
            if (isAsc) {
                return a[property] > b[property] ? 1 : -1;
            } else {
                return b[property] > a[property] ? 1 : -1;
            }
        });
        setSortedData(data);
    };

    const handleOpenPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Grid>
                <TableContainer sx={{ maxHeight: 500, width: '100%' }}>
                    <Table stickyHeader size="small" sx={{ p: '10px' }}>
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        // align={column.align}
                                        style={{ color: '#7753a4', fontWeight: 'bold', }}
                                        sortDirection={orderBy === column.id ? order : false}
                                    >
                                        {column.sortable ? (<TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : 'asc'}
                                            onClick={handleSort(column.id)}
                                        >
                                            {column.label}

                                        </TableSortLabel>) : (
                                            column.label
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedData
                                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                            {columns.map((column) => {
                                                const value = column.id === 'action' ? row : row[column.id];
                                                return (
                                                    <TableCell key={column.id}
                                                    // align={column.align}
                                                    >
                                                        {column.format && value
                                                            ? column.format(value)
                                                            : column.render
                                                                ? column.render(value, props)
                                                                : value ? value : '-'}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>

                    {sortedData.length === 0 && <div style={{
                        margin: '32px 0',
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '0.75em',
                        textTransform: 'uppercase',
                    }}>
                        No Indents created  </div>}
                </TableContainer>
                {(props.parent === 'quote' && indents.length === 0) && <div style={{ margin: "10px 0px" }}>
                    <Tooltip title="Create Indent" arrow>
                        <Fab size="medium" color="primary" aria-label="add" onClick={() => onEdit()}>
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                </div>
                }
            </Grid >
            <ApproveOrRejectIndentModal
                isOpen={isOpenModal}
                selectedData={selectedData || {}}
                handleCloseModal={handleCloseModal}
                parent='indents'
                // selectedQuote={selectedQuote}
                onRefresh={onRefresh}
            />
        </>
    )
}