import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import { capitalize } from 'lodash';
import { useNavigationHistory } from '../contexts/NavigationHistoryContext';

const Breadcrumb = () => {
    const navigate = useNavigate();
    const { history = [], popFromHistory, clearHistory } = useNavigationHistory();

    const handleGoBack = (index) => {
        for (let i = history.length - 1; i > index; i--) {
            popFromHistory();
        }
    };

    return (
        <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    sx={{ display: 'flex', alignItems: 'center', fontSize: 'small', textDecoration: 'none', }}
                    color="inherit"
                    to={"/"}
                    onClick={() => { clearHistory(); navigate("/") }}
                    style={{ fontSize: '12px', color: 'black', textDecoration: 'none', }}
                >
                    <HomeIcon sx={{ mr: 0.5 }} fontSize="10px" />
                    Home
                </Link>
                {history.map((path, index) => {
                    const isLast = index === history.length - 1;

                    return (
                        !isLast && <Link
                            sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', }}
                            color="inherit"
                            to={path.url}
                            onClick={() => { handleGoBack(index) }}
                            style={{ fontSize: '12px', color: 'black', textDecoration: 'none', }}
                        >
                            {capitalize(path.title)}
                        </Link>
                    )


                })
                }
            </Breadcrumbs>
        </div>
    );
};

export default Breadcrumb;
