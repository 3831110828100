import React, { useState, useRef, useEffect } from "react";
import {
    Typography, Button, TextField, FormHelperText,
    Tooltip, Backdrop, CircularProgress, Alert,
    Avatar,
    Box,
} from "@mui/material";
import { RequisitionApi } from "../../../api/RequisitionApi";
import { saveAs } from "file-saver";
import RequisitionList from "../components/RequisitionList";
import { useSelector } from "react-redux";
import moment from "moment";
import { debounce } from "lodash";
import RequisitionHeader from "../components/RequisitionHeader";
import AlertComponent from "../../../utills/AlertComponent";


export default function RequisitionContainer() {
    const [csvfile, setCsvfile] = useState(null);
    const [requisitions, setRequisitions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const filesInput = useRef(null);

    const [searchQuery, setSearchQuery] = useState(null);
    const userWarehouse = useSelector(x => x.GlobalSettingSlice.userWarehouse);
    const deliveryDate = useSelector(x => x.GlobalSettingSlice.deliveryDate);

    const isDisabled = moment(deliveryDate).isBefore(moment().format('YYYY-MM-DD'));



    useEffect(() => {
        fetchRequisitions();
    }, [deliveryDate, userWarehouse]);

    const onClickDownloadCsv = async () => {
        try {
            setMessage(null);
            setLoading(true);
            const response = await RequisitionApi.getCsv(userWarehouse.id, deliveryDate);
            // console.log(response.headers.get('Content-Disposition'));
            const { data = {} } = response;
            const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
            setLoading(false);
            setMessage({ message: "Downloaded Successfuly", type: 'success' });
            saveAs(blob, `products`);
        } catch (error) {
            setLoading(false);
            let message = "Something went wrong! Please try again later";
            if (error && error.response && error.response.data) {
                message = error.response.data;
            }
            setMessage({ message: message, type: 'error' });
        }
    }

    const handleFileupload = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('warehouseId', userWarehouse.id);
        formData.append('deliveryDate', deliveryDate);
        try {
            setMessage('');
            setLoading(true);
            const response = await RequisitionApi.updateRequisition(formData);
            setMessage({ message: response.data.message, type: 'success' });
            fetchRequisitions(deliveryDate);
        } catch (error) {
            setLoading(false);
            let message = "Something went wrong! Please try again later";
            if (error && error.response && error.response.data && error.response.data.message) {
                message = error.response.data.message;
            }
            setMessage({ message: message, type: 'error' });
        }
    }

    const fetchRequisitions = debounce(async () => {
        try {
            setMessage('');
            setLoading(true);
            const response = await RequisitionApi.getDemands(userWarehouse.id, '', deliveryDate, searchQuery);
            const { data: { data = [] } } = response;
            setRequisitions(data);
            setLoading(false);
        }
        catch (error) {
            setLoading(false);
            let message = "Something went wrong! Please try again later";
            if (error && error.response && error.response.data && error.response.data.message) {
                message = error.response.data.message;
            }
            setRequisitions([]);
            setMessage({ message: message, type: 'error' });
        }
    }, 500);

    const handleDeleteRequisition = async (id) => {
        try {
            setMessage('');
            setLoading(true);
            const response = await RequisitionApi.deleteRequition(id);
            setMessage({ message: response.data.message, type: 'success' });
            fetchRequisitions(deliveryDate);
        }
        catch (error) {
            setLoading(false);
            let message = "Something went wrong! Please try again later";
            if (error && error.response && error.response.data && error.response.data.message) {
                message = error.response.data.message;
            }
            setMessage({ message: message, type: 'error' });
        }
    }
    const clearMessage = () => {
        setMessage(null);
    }

    return (
        <>
            <RequisitionHeader
                handleFileupload={handleFileupload}
                onClickDownloadCsv={onClickDownloadCsv}
                isDisabled={isDisabled}
                setSearchQuery={setSearchQuery}
                searchQuery={searchQuery}
                fetchRequisitions={fetchRequisitions}
            />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress />
            </Backdrop>
            {message && <AlertComponent
                message={message.message}
                severity={message.type}
                onClose={clearMessage}
            />}
            {requisitions && requisitions.length > 0 &&
                <RequisitionList
                    data={requisitions}
                    onDelete={handleDeleteRequisition}
                    isDisabled={isDisabled}
                />}
        </>
    )

}