import React, { useState, useEffect } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    TableHead,
    Grid,
    Button,
    Tooltip,
    TableSortLabel,
    Box,
    Modal,
    Typography,
    Avatar,
    Chip,
} from "@mui/material";
import moment from "moment";
import DeleteIcon from '@mui/icons-material/Delete';
import QuoteList from "../../quotes/components/QuotesList";
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PURCHASE_MANAGEMENT_COLORS from './../../../utills/constants';
import PopoverConfirm from "../../../utills/PopoverConfirmation";
import STATUS_COLOURS from "./../../../utills/constants";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    // boxShadow: 24,
    p: 4,
    // borderRadius: '5px'
};

function Row(props) {
    const { requisitionStatuses } = useSelector(x => x.StatusSlice);
    const { row, columns } = props;

    return (
        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
            {columns.map((column) => {
                const value = column.id === 'action' || column.id === 'when' ? row : row[column.id];
                return (
                    <TableCell key={column.id}
                    // align={column.align}
                    >
                        {column.format && value
                            ? column.format(value)
                            : column.render
                                ? column.render(value, props)
                                : value}
                    </TableCell>
                );
            })}
        </TableRow>
    )
}

export default function RequisitionList(props) {
    const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, data = [] } = props;
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('skuId');
    const [sortedData, setSortedData] = useState(data);
    const [selectedData, setSelectedData] = useState(null);
    const [isOpen, handleModalState] = useState(false);
    const navigate = useNavigate();
    const { requisitionSource } = useSelector(x => x.StatusSlice);
    const [sortConfig, setSortConfig] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const { requisitionStatuses } = useSelector(x => x.StatusSlice);
    const columns = [
        {
            id: 'image', label: 'Image',
            render: (value, props) => <div>
                <img style={{ height: '50px', maxWidth: '100%', width: '50px', objectFit: 'scale-down' }} src={value} />
            </div>
        },
        { id: 'familyName', label: 'Family Name', sortable: true },
        { id: 'size', label: 'Size', sortable: true },
        { id: 'quantity', label: 'Quantity', sortable: true },
        {
            id: 'lastUpdated',
            label: 'Last Updated',
            sortable: true,
            format: (value) => moment(value).format('lll')
        },
        {
            id: 'when',
            label: 'When',
            sortable: true,
            format: (value) => moment(value.lastUpdated).fromNow()
        },
        {
            id: 'source',
            label: 'Initiated By',
            sortable: true,
            format: (value) => requisitionSource[value] || value
        },
        {
            id: 'status',
            label: 'Status',
            sortable: true,
            render: (value) => <Chip
                size="small"
                color={STATUS_COLOURS[value]}
                label={(requisitionStatuses[value] && requisitionStatuses[value].toUpperCase()) || value}
                style={{ fontSize: '10px', fontWeight: 'bold' }}
                variant="outlined"
            />
        },
        {
            id: 'action',
            label: 'Action',
            render: (value) => <div style={{ display: "flex" }}>
                {value.quotesCount > 0 && <Tooltip title="View Quotes" arrow>
                    <span>
                        <Button
                            variant="outlined"
                            size="small"
                            // color={PURCHASE_MANAGEMENT_COLORS['QUOTES']}
                            style={{
                                marginRight: '10px', minWidth: '45px', fontSize: '14px', fontWeight: 'bolder',
                                borderColor: PURCHASE_MANAGEMENT_COLORS['QUOTES']
                            }}
                            onClick={() => navigate(`/quotes?requisitionId=${value.id}`)}
                        >
                            <Avatar
                                sx={{
                                    width: 22, height: 22, fontSize: '14px', fontWeight: 'bolder',
                                    bgcolor: PURCHASE_MANAGEMENT_COLORS['QUOTES']
                                }}
                            >
                                Q
                            </Avatar>
                        </Button>
                    </span>
                </Tooltip>}
                <Tooltip title="Cancel" arrow>
                    <span>
                        <Button
                            variant="outlined"
                            size="small"
                            color="error"
                            style={{ minWidth: '45px' }}
                            onClick={handleOpenPopover}
                            disabled={value.status !== 'NEW' || props.isDisabled}
                        >
                            <DeleteIcon />
                        </Button>
                    </span>

                </Tooltip>
                <PopoverConfirm
                    onConfirm={() => { props.onDelete(value.id); handleClosePopover() }}
                    onCancel={handleClosePopover}
                    anchorEl={anchorEl}
                    title="Are you sure you want to delete this item?"
                />
            </div>
        }

        // {
        //     id: 'active',
        //     label: 'Status',
        //     render: (value) => <div style={{
        //         width: 'fit-content',
        //         color: value ? '#006644' : '#BF2600',
        //         backgroundColor: value ? '#E3FCEF' : '#FFEBE6',
        //         textTransform: 'uppercase',
        //         fontSize: '11px',
        //         fontWeight: '700',
        //         padding: '2px 4px 3px 4px',
        //         fontFamily: 'sans-serif'
        //     }} > {value ? 'Active' : 'Inactive'}</div >
        // },

    ];

    // useEffect(() => {
    //     setSortedData(data);
    //     setOrder('asc');
    //     setOrderBy('skuId');
    // }, [props, requisitionSource]);

    const handleOpenPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const handleSort = (key) => {
        const sortedConfigIndex = sortConfig.findIndex((item) => item.key === key);
        let newConfig = [];
        if (sortedConfigIndex === -1) {
            // If the column is not already sorted, add it to the config as ascending
            newConfig = [...sortConfig, { key, direction: 'asc' }]
            setSortConfig(newConfig);
        } else if (sortConfig[sortedConfigIndex].direction === 'asc') {
            // If the column is currently sorted in ascending order, change it to descending
            newConfig = [...sortConfig];
            newConfig[sortedConfigIndex].direction = 'desc';
            setSortConfig(newConfig);
        } else {
            // If the column is currently sorted in descending order, remove it from the config
            newConfig = [...sortConfig];
            newConfig.splice(sortedConfigIndex, 1);
            // newConfig[sortedConfigIndex].direction = 'asc';
            setSortConfig(newConfig);
        }
        const sortData = [...data].sort((a, b) => {
            for (const { key, direction } of newConfig) {
                if (a[key] !== b[key]) {
                    if (direction === 'asc') {
                        return a[key] > b[key] ? 1 : -1;
                    } else {
                        return b[key] > a[key] ? 1 : -1;
                    }
                }
            }
            return 1;
        });
        setSortedData(sortData);

    };

    const handleModalStatus = (data) => {
        if (!data) {
            handleModalState(false);
            setSelectedData(null);
        } else {
            let quoteList = data.quotesList.map(el => {
                return { ...el, familyName: data.familyName, size: data.size, image: data.image }
            });
            setSelectedData(quoteList);
            handleModalState(true)
        }
    }

    return (
        <>
            <Grid>
                <TableContainer sx={{ maxHeight: 500, width: '100%' }}>
                    <Table stickyHeader size="small" sx={{ p: '10px' }}>
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        // align={column.align}
                                        style={{ color: '#7753a4', fontWeight: 'bold' }}
                                    // sortDirection={orderBy === column.id ? order : false}
                                    >
                                        {column.sortable ? (<TableSortLabel
                                            active={sortConfig.find((item) => item.key === column.id)}
                                            direction={
                                                sortConfig.find((item) => item.key === column.id)?.direction || 'asc'
                                            }
                                            onClick={() => handleSort(column.id)}
                                        >
                                            {column.label}
                                        </TableSortLabel>) : (
                                            column.label
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedData
                                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <Row row={row} columns={columns} key={row.id} />

                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {/* <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
            <Modal
                open={isOpen}
                onClose={(event, reason) => handleModalStatus}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} >
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography id="modal-modal-title" variant="body1">
                            Quotes
                        </Typography>
                        <CancelIcon color="error" onClick={() => handleModalStatus(null)} style={{ cursor: 'pointer' }} />
                    </div>
                    <Grid style={{ border: '1px solid', marginTop: '10px', marginBottom: "10px", padding: '0 1em' }}>
                        <QuoteList
                            data={selectedData}
                            // selectedProduct={selectedProduct}
                            // showIndent={''}
                            parent="requisition"
                        />
                    </Grid>
                </Box>
            </Modal>

        </>
    )

}