import React, { useEffect } from "react";
import { Router, Routes, Route, useLocation, Navigate } from "react-router-dom";
import Login from "../modules/auth/Login";
import Home from "./Home";
import Cookies from "universal-cookie";
import Layout from "../layouts/Layout";
import Page404 from "./NotFound";
import VendorContainer from "../modules/vendors/container/VendorContainer";
import UserContainer from "../modules/users/container/UserContainer";
import RequisitionContainer from "../modules/requisition/container/RequisitionContainer";
import IndentContainer from "../modules/indents/container/IndentContainer";
import QuoteContainer from "../modules/quotes/container/QuoteContainer";
import { MetaDataApi } from "../api/MetaDataApi";
import { useDispatch } from "react-redux";
import { setEnums } from "../reducers/StatusSlice";
import PurchaseConfirmationContainer from "../modules/purchaseConfirmations/container/PurchaseConfirmationContainer";
import ShipmentContainer from "../modules/shipments/container/ShipmentContainer";
import SetPassword from "../modules/auth/SetPassword";
import AgentContainer from "../modules/agents/container/AgentContainer";

const ProtectedRoute = ({ children }) => {
    const location = useLocation();
    const cookies = new Cookies();
    if (!cookies.get('access_token')) {
        return <Navigate to='/login' state={{ prev: location.pathname }} />
    }
    return children;
};

const Root = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const cookies = new Cookies();
    const isAuthenticated = cookies.get('access_token');

    useEffect(() => {
        if (isAuthenticated) {
            getEnums();
        }
    }, []);

    const getEnums = async () => {
        try {
            const response = await MetaDataApi.getEnums();
            const { data } = response;
            dispatch(setEnums(data));
        } catch (error) {

        }
    }

    return (
        <Routes>
            <Route path="/" element={<ProtectedRoute><Layout /></ProtectedRoute>}>
                <Route exact path="/" element={<Home />} />
                <Route path="/vendors" element={<VendorContainer />} />
                <Route path="/users" element={<UserContainer />} />
                <Route path="/requisitions" element={<RequisitionContainer />} />
                <Route path="/indents" element={<IndentContainer />} />
                <Route path="/quotes" element={<QuoteContainer />} />
                <Route path="/purchase-confirmations" element={<PurchaseConfirmationContainer />} />
                <Route path="/shipments" element={<ShipmentContainer />} />
                <Route path="/agents" element={<AgentContainer />} />
            </Route>
            <Route path="/login" Component={Login}
                state={isAuthenticated ? {} : { prev: location.pathname }}
            />
            <Route exact path="/forgot-password" element={<SetPassword />} />
            <Route path="*" Component={Page404} />
        </Routes>

    )
}
export default Root;