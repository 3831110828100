import React, { useState, useRef } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import SideBarMenu from './SideBarMenu';
import { logout } from '../reducers/AuthSlice';
import { clearState } from '../reducers/GlobalSettingSlice';
import { setDeliveryDate } from '../reducers/GlobalSettingSlice';
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import { Divider, TextField } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import HomeIcon from '@mui/icons-material/Home';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import WarehouseSelectionModal from '../utills/WarehouseSelection';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { styled } from '@mui/system';
import { persistor } from './../store/configureStore';
import { useNavigationHistory } from '../contexts/NavigationHistoryContext';

const HiddenBordersTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: 'none',
            color: "#000000",
        },
        '& .MuiSvgIcon-root': {
            color: "white",
        },
    },
    position: 'relative', // Add this to make the container relative

}));

const pages = [
    { label: 'Home', path: '/' },
    { label: 'Monitor', path: "/monitor" },
];
const settings = ['Logout'];

const darkTheme = createTheme({
    palette: {
        primary: {
            main: '#7753a4',
        },
    },
});

function Navbar() {
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [isOpenSideMenu, setOpenSideMenu] = useState(false);
    const [isOpenWarehouseModal, setWarehouseOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cookies = new Cookies();
    const userDetails = useSelector(x => x.authSlice.user);
    const userWarehouse = useSelector(x => x.GlobalSettingSlice.userWarehouse);
    const deliveryDate = useSelector(x => x.GlobalSettingSlice.deliveryDate);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const inputRef = useRef(null);
    const { clearHistory } = useNavigationHistory();

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = (path = null) => {
        setAnchorElNav(null);
        if (path) {
            navigate(path);
            clearHistory();
        }
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const toggleDrawer = (state) => {
        setOpenSideMenu(state);
    }
    const handleLogout = () => {
        cookies.remove('access_token');
        handleCloseUserMenu();
        persistor.purge();
        dispatch(logout());
        dispatch(clearState());
        navigate('/login');
    }

    const openWarehouseSelection = (isOpen) => {
        setWarehouseOpen(isOpen);
    }

    const toggleDatePicker = () => {
        setIsDatePickerOpen(!isDatePickerOpen);
    };

    const handleDateChange = (event) => {
        dispatch(setDeliveryDate(event.target.value));
        clearHistory();
    }

    return (
        <>
            <ThemeProvider theme={darkTheme}>
                <AppBar position="sticky" style={{ paddingRight: '24px', paddingLeft: '24px', width: '100%', overflow: "hidden", flexWrap: "wrap" }}>
                    <Toolbar disableGutters >
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ display: { xs: 'flex', md: 'flex' }, mr: { xs: 0, md: 2 } }}
                            onClick={() => toggleDrawer(true)}
                        >
                            <MenuIcon />
                        </IconButton>
                        {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            onClick={() => navigate("/")}
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                                cursor: "pointer",
                            }}
                        >
                            COMMEX
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            {/* <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: { xs: 'block', md: 'none' },
                                    }}
                                >
                                    {pages.map((page) => (
                                        // <Button size="large">
                                        //     {page}
                                        // </Button>
                                        <MenuItem key={page.path} onClick={() => handleCloseNavMenu(page.path)}>
                                            <Typography textAlign="center">{page.label}</Typography>
                                        </MenuItem>
                                    ))}
                                </Menu> */}
                        </Box>

                        {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href=""
                            sx={{
                                mr: 1,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 100,
                                letterSpacing: '.1rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            Commex
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {pages.map((page) => (
                                <Button
                                    key={page.path}
                                    onClick={() => handleCloseNavMenu(page.path)}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    {page.label}
                                </Button>
                            ))}
                        </Box>

                        <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: "center", }}>
                            <Tooltip title="Delivery Date" arrow>
                                <MenuItem sx={{ padding: '0px' }}>
                                    <HiddenBordersTextField
                                        type="date"
                                        hiddenLabel
                                        variant="outlined"
                                        size="small"
                                        onChange={handleDateChange}
                                        value={deliveryDate}
                                        sx={{
                                            input: {
                                                color: "white",
                                                //   background: "green"
                                            },
                                            '& svg': { color: 'white' },
                                        }}
                                    />
                                </MenuItem>
                            </Tooltip>

                            <Tooltip title="Switch Warehouse" arrow>
                                <MenuItem onClick={() => openWarehouseSelection(true)} >
                                    <HomeOutlinedIcon style={{ fontSize: '18px', }} size="small" />
                                    <Typography variant="body1" color="inherit" style={{ marginRight: '5px', marginLeft: '2px' }}>
                                        {userWarehouse.name}
                                    </Typography>
                                    <ArrowDropDownIcon size="small" />

                                </MenuItem>
                            </Tooltip>

                            {/* <Menu
                                    anchorEl={anchorWarehouse}
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    open={Boolean(anchorWarehouse)}
                                    onClose={handleCloseWarehouseMenu}
                                    onClick={handleCloseWarehouseMenu}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                                >

                                    <MenuItem onClick={() => {
                                         setAnchorWarehouse(null);openWarehouseSelection(true);
                                    }}>
                                        <ListItemIcon>
                                            <WarehouseIcon fontSize="small" />
                                        </ListItemIcon>
                                        Switch Warehouse <ChevronRightIcon size={'small'} />
                                    </MenuItem>
                                    <Divider />


                                </Menu> */}



                            <Tooltip title="Account settings" arrow>
                                <MenuItem onClick={handleOpenUserMenu} >
                                    <IconButton sx={{ p: 0 }} size={'small'}>
                                        <Avatar alt={userDetails.name}
                                            sx={{ width: 25, height: 25, bgcolor: 'white', color: '#7753a4', fontSize: "16px" }}
                                        >{(userDetails.name.toUpperCase()).charAt(0)}
                                        </Avatar>
                                    </IconButton>
                                    <ArrowDropDownIcon size="small" />
                                </MenuItem>
                            </Tooltip>

                            <Menu
                                anchorEl={anchorElNav}
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                                onClick={handleCloseUserMenu}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                            >
                                <MenuItem>
                                    <ListItemIcon>
                                        <Avatar alt={userDetails.name}
                                            sx={{ width: 25, height: 25, bgcolor: '#7753a4', color: 'white', fontSize: "14px" }}
                                        >{(userDetails.name.toUpperCase()).charAt(0)}
                                        </Avatar>
                                    </ListItemIcon>
                                    {userDetails.name}
                                </MenuItem>
                                <Divider />
                                {/* <MenuItem>
                                        <ListItemIcon>
                                            <HomeIcon fontSize="small" />
                                        </ListItemIcon>
                                        {userWarehouse.name}
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        openWarehouseSelection(true)
                                    }}>
                                        <ListItemIcon>
                                            <WarehouseIcon fontSize="small" />
                                        </ListItemIcon>
                                        Switch Warehouse <ChevronRightIcon size={'small'} />
                                    </MenuItem>
                                    <Divider /> */}

                                <MenuItem onClick={() => handleLogout()}>
                                    <ListItemIcon>
                                        <Logout fontSize="small" />
                                    </ListItemIcon>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </Box>

                    </Toolbar>
                </AppBar>
                <Drawer
                    // anchor={anchor}
                    open={isOpenSideMenu}
                    onClose={() => toggleDrawer(false)}
                >
                    <SideBarMenu onClose={() => toggleDrawer(false)} />
                </Drawer>
            </ThemeProvider >
            <WarehouseSelectionModal
                open={isOpenWarehouseModal}
                handleClose={() => openWarehouseSelection(false)}
                warehouses={userDetails.warehouses}
            />

        </>
    );
}



export default Navbar
