import { ApiService } from "./NetworkHandler";
const baseUrl = '/api/v1/internal-users';

export const AuthAPI = {
    login: function (email, data) {
        return ApiService.sendRequest('post', `${baseUrl}/login?emailId=${email}`, data);
    },
    generateOtp: function (email) {
        return ApiService.sendRequest('post', `${baseUrl}/generate-otp?email=${email}`);
    },
    getWarehouseByUserId: function (userId) {
        return ApiService.sendRequest('get', `${baseUrl}/get-warehouses?userId=${userId}`);
    },
    resetPassword: function (email, data) {
        return ApiService.sendRequest('post', `${baseUrl}/reset-pin?emailId=${email}`, data);
    }
}