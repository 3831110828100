import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    TableHead,
    Grid,
    Button, IconButton,
    Collapse,
    Chip,
    TableSortLabel,
} from "@mui/material";
import moment from "moment";
import EditIcon from '@mui/icons-material/Edit';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const columns = [
    // { id: 'id', label: 'Id' },
    { id: 'name', label: 'Name', sortable: true, },
    // {
    //     id: 'timeCreated',
    //     label: 'Created On',
    //     format: (value) => moment(value).format('ll'),
    // },
    // {
    //     id: 'timeUpdated',
    //     label: 'Updated On',
    //     format: (value) => moment(value).format('ll'),
    // },
    {
        id: 'mobile',
        label: 'Mobile',
        sortable: true,
    },
    // {
    //     id: 'active',
    //     label: 'Status',
    //     render: (value) => <div style={{
    //         width: 'fit-content',
    //         color: value ? '#006644' : '#BF2600',
    //         backgroundColor: value ? '#E3FCEF' : '#FFEBE6',
    //         textTransform: 'uppercase',
    //         fontSize: '11px',
    //         fontWeight: '700',
    //         padding: '2px 4px 3px 4px',
    //         fontFamily: 'sans-serif'
    //     }} > {value ? 'Active' : 'Inactive'}</div >
    // },
    // {
    //     id: 'action',
    //     label: 'Action',
    //     render: () => <div>
    //         {/* //     <Button variant="outlined" size="small" style={{ minWidth: '45px' }}>
    //     //         <EditIcon />
    //     //     </Button> */}
    //     </div>
    // }
];

function Row(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);
    const childColumns = [
        {
            id: 'name',
            label: 'Name',
            sortable: true
        },
        {
            id: 'mobile',
            label: 'Mobile',
            sortable: true
        },
        {
            id: 'harbours',
            label: 'Harbours',
        }
    ];

    return (
        <><TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
            <TableCell style={{ width: '10px' }}>
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </TableCell>
            {columns.map((column) => {
                const value = column.id === 'action' ? row : row[column.id];
                return (
                    <TableCell key={column.id}
                    // align={column.align}
                    >
                        {column.format && value
                            ? column.format(value)
                            : column.render
                                ? column.render(value)
                                : value}
                    </TableCell>
                );
            })}
        </TableRow>
            <TableRow >
                <TableCell align="right" style={{ paddingBottom: 0, paddingTop: 0, marginLeft: 'auto', border: open ? "1px solid black" : "" }} colSpan={9}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {/* <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                History
                            </Typography> */}
                        <TableContainer>
                            <Table size="small" aria-label="purchases" sx={{ border: '2px sold black', }} >

                                <TableHead>
                                    {childColumns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.id !== 'harbours' ? 'right' : "center"}
                                            style={{ color: '#7753a4', fontWeight: 'bold', fontSize: '12px' }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableHead>
                                <TableBody>
                                    {row.agents.map((item) => {
                                        return (<TableRow>
                                            {childColumns.map((col) => (
                                                // const value = col.id === 'action' ? item : item[col.id]; <TableRow key={item.id}> */}
                                                <TableCell component="th" scope="row"
                                                    align={col.id !== 'harbours' ? 'right' : "center"}
                                                >
                                                    {col.id !== 'harbours' ? item[col.id]
                                                        :
                                                        <div>
                                                            {item['harbours'].map(el => {
                                                                return (<Chip
                                                                    key={el.id}
                                                                    size="small"
                                                                    color={'primary'}
                                                                    label={el.name.toUpperCase()}
                                                                    style={{ fontSize: '10px', fontWeight: 'bold', marginRight: '5px' }}
                                                                    variant="outlined"
                                                                />)
                                                            }
                                                            )}  </div>}
                                                </TableCell>
                                            ))}
                                        </TableRow>)
                                    }
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {/* </Box> */}
                    </Collapse>
                </TableCell>
            </TableRow>
        </>

    );
}


export default function VendorList(props) {
    const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, data } = props;
    console.log(data);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('skuId');
    const [sortedData, setSortedData] = useState(data);

    useEffect(() => {
        setSortedData(data);
        setOrder('asc');
        setOrderBy('skuId');
    }, [props]);

    const handleSort = (property) => () => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        // Sort the data based on the selected column and order
        const sortData = [...sortedData].sort((a, b) => {
            if (isAsc) {
                return a[property] > b[property] ? 1 : -1;
            } else {
                return b[property] > a[property] ? 1 : -1;
            }
        });
        setSortedData(sortData);
    };


    return (
        <>
            <Grid>
                <TableContainer sx={{ maxHeight: 500, width: '100%' }}>
                    <Table stickyHeader size="small" sx={{ p: '10px' }}>

                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        // align={column.align}
                                        style={{ color: '#7753a4', fontWeight: 'bold' }}
                                        sortDirection={orderBy === column.id ? order : false}
                                    >
                                        {column.sortable ? (<TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : 'asc'}
                                            onClick={handleSort(column.id)}
                                        >
                                            {column.label}

                                        </TableSortLabel>) : (
                                            column.label
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedData
                                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return <Row key={row.id} row={row} />
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>


            </Grid>

            {/* <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
        </>

    );
}