import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  Box,
  Grid,
  CardHeader,
  Avatar,
} from '@mui/material';
import { Link } from "react-router-dom";
import './style.css';
import PURCHASE_MANAGEMENT_COLORS from './../utills/constants';

const items = [
  {
    item: 'User Management',
    links: [
      {
        path: '/users',
        label: 'Users',
      },
      {
        path: '/users-groups',
        label: 'User Groups',
      },
    ]
  },
  {
    item: 'Vendor Management',
    links: [
      {
        path: '/vendors',
        label: 'Vendors',
      },
    ]
  },
  {
    item: 'Agent Management',
    links: [
      {
        path: '/agents',
        label: 'Agents',
      },
    ]
  },
  {
    item: 'Purchase Management',
    links: [
      {
        path: '/requisitions',
        label: 'Requisitions',
        icon: '',
      },
      {
        path: '/quotes',
        label: 'Quotes',
        icon: '',
      },
      {
        path: '/indents',
        label: 'Indents',
        icon: '',
      },
      {
        path: '/purchase-confirmations',
        label: 'Purchase Confirmations',
        icon: '',
      },
      {
        path: '/shipments',
        label: 'Shipments',
        icon: '',
      },
    ]
  }];

export default function Home() {
  return (
    <div style={{ padding: '20px 40px 10px 40px', flexGrow: 1, }}>
      <Grid
        container
        spacing={4}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        {items.map(el => {
          return (<Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardContent>
                {<Typography sx={{ fontSize: 14, fontWeight: 'bold', borderBottom: '1px solid #e3e0e0', pb: 1, mb: 2 }} color="primary" gutterBottom>
                  {el.item}
                </Typography>}
                {el.links.map(link => {
                  return (<Link to={link.path} className="child" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', padding: '4px' }}>
                    {link.icon != undefined && <Avatar src={link.icon}
                      sx={{
                        width: 20, height: 20, mr: 1, fontSize: '14px',
                        bgcolor: el.item == "Purchase Management" ? PURCHASE_MANAGEMENT_COLORS[(link.label.toUpperCase())] || "" : ""
                      }}
                    >
                      {!link.icon && link.label.charAt(0)}
                    </Avatar>}
                    <Typography variant="body2">{link.label}</Typography>
                  </Link>)
                })}
              </CardContent>
            </Card>

          </Grid>)
        })}

      </Grid >
    </div >

  );
}
