import React, { useEffect, useState } from "react";
import VendorSearch from "../components/VendorSearch";
import VendorList from "../components/VendorList";
import { VendorApi } from "../../../api/VendorApi";
import { useSelector } from "react-redux";
import moment from "moment";

export default function VendorContainer() {
    const [vendors, setVendors] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const userWarehouse = useSelector(x => x.GlobalSettingSlice.userWarehouse);
    const deliveryDate = useSelector(x => x.GlobalSettingSlice.deliveryDate);
    const isDisabled = moment(deliveryDate).isBefore(moment().format('YYYY-MM-DD'));
    const [filteredData, setFilteredData] = useState([]);
    const [searchQuery, setSearchQuery] = useState(null);



    useEffect(() => {
        fetchVendors();
    }, [userWarehouse]);

    useEffect(() => {
        search();
    }, [searchQuery, vendors]);

    const fetchVendors = async () => {
        try {
            const response = await VendorApi.getVendors(userWarehouse.id);
            const { data: { data = [] } } = response;
            setVendors(data);
        } catch (error) {
            setVendors([]);
            setFilteredData([]);
        }
    }

    const search = () => {
        if (searchQuery) {
            let data = vendors.filter(el => el.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                el.mobile.toLowerCase().includes(searchQuery.toLowerCase()))
            setFilteredData(data);
        } else {
            setFilteredData(vendors);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeSearchInput = (value) => {
        setSearchQuery(value);
    }

    return (
        <>
            <VendorSearch
                handleChangeSearchInput={handleChangeSearchInput}
            />
            <VendorList
                data={filteredData}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleChangePage={handleChangePage}

            />
        </>

    )
}