import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    indentCancellationReason: {},
    indentStatuses: {},
    requisitionSource: {},
    poCancellationReason: {},
    purchaseOrderStatus: {},
    shipmentStatuses: {},
    requisitionStatuses: {},
};

export const StatusSlice = createSlice({
    name: 'Status',
    initialState,
    reducers: {
        setEnums: (state, action) => {
            state.indentCancellationReason = action.payload.indentCancellationReason;
            state.indentStatuses = action.payload.indentStatuses;
            state.requisitionSource = action.payload.requisitionSource;
            state.poCancellationReason = action.payload.poCancellationReason;
            state.purchaseOrderStatus = action.payload.purchaseOrderStatus;
            state.shipmentStatuses = action.payload.shipmentStatuses;
            state.requisitionStatuses = action.payload.requisitionStatuses;
        },
        clearState: (state) => {
            return initialState;
        }
    }
});

export const { setEnums } = StatusSlice.actions;
export default StatusSlice.reducer;

