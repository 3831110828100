import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import {
    Container,
    Box,
    Typography,
    Backdrop,
    CircularProgress,
    Alert,
    TextField,
    Button,
    Grid,
    Link,
} from '@mui/material';
import { AuthAPI } from "../../api/AuthApi";
import AuthTheme from './AuthTheme';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import Cookies from "universal-cookie";
import AlertComponent from "../../utills/AlertComponent";


export default function SetPassword() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({});
    const [isSendOtp, setSendotp] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [error, setError] = useState({});

    const cookies = new Cookies();
    const isAuthenticated = cookies.get('access_token');

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        }
    })

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(
            {
                ...formData,
                [name]: value
            }
        )
        if (name === 'userName') setInvalidEmail(false);
    }

    const sendOtp = async () => {
        setMessage(null);
        setLoading(true);
        try {
            const response = await AuthAPI.generateOtp(formData.userName);
            setSendotp(true);
            setMessage({ message: response.data.message, type: 'success' });
            setLoading(false);
        } catch (error) {
            setLoading(false);
            let message = "Something went wrong! Please try again later";
            if (error && error.response && error.response.data && error.response.data.message) {
                message = error.response.data.message;
            }
            setMessage({ message: message, type: 'error' });
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const buttonName = event.nativeEvent.submitter.name;
        if (buttonName === 'setPassword') {
            if (validateForm(['userName', 'otp', 'newPin'])) {
                setPassword();
            }
        } else {
            if (validateForm(['userName'])) {
                sendOtp();
            }
        }
    }

    const validateForm = (data) => {
        let isValid = true;
        let error = {};
        for (let key in data) {
            if (!isPropertyValid(data[key], formData[data[key]])) {
                isValid = false;
                error[data[key]] = true;

            } else {
                error[data[key]] = false;
            }
        }
        setError(error);
        return isValid;
    }

    const isPropertyValid = (key, value) => {
        if (!value) {
            return false;
        }
        if (key === 'userName') {
            if (!isValidEmail(value)) {
                setInvalidEmail(true);
                return false
            }
        }
        return true;
    }

    const isValidEmail = (email) => {
        // Regular expression for a basic email validation
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(email);
    };

    const setPassword = async () => {
        setMessage(null);
        setLoading(true);
        try {
            const response = await AuthAPI.resetPassword(formData.userName, formData);
            setMessage({ message: response.data.message, type: 'success' });
            setLoading(false);
            navigate('/login');
        } catch (error) {
            setLoading(false);
            let message = "Something went wrong! Please try again later";
            if (error && error.response && error.response.data && error.response.data.message) {
                message = error.response.data.message;
            }
            setMessage({ message: message, type: 'error' });
        }
    }

    const clearMessage = () => {
        setMessage(null);
    }

    return (
        <ThemeProvider theme={AuthTheme}>
            <CssBaseline />
            <Container component="main" maxWidth="xs" style={{
                display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: "center", height: "100vh"
            }}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress />
                </Backdrop>
                {message && <AlertComponent
                    message={message.message}
                    severity={message.type}
                    onClose={clearMessage}
                />}

                <Box
                    sx={{
                        boxShadow: 3,
                        borderRadius: 2,
                        p: 4,
                        pt: 2,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: '#ffffff',

                    }}
                >
                    <Typography component="h1" variant="h4" color={"primary"} align="center">
                        Commex
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="userName"
                            label="Email Address"
                            name="userName"
                            autoComplete="off"
                            autoFocus
                            size={"small"}
                            value={formData.userName || ''}
                            onChange={handleChange}
                            disabled={isSendOtp}
                            error={error && error.userName}
                            helperText={invalidEmail && "Please enter a valid email"}
                        />
                        {isSendOtp && <><TextField
                            margin="normal"
                            required
                            fullWidth
                            id="otp"
                            label="OTP"
                            name="otp"
                            autoFocus
                            autoComplete="off"
                            size={"small"}
                            value={formData.otp || ''}
                            onChange={handleChange}
                            error={error && error.otp}
                        />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="newPin"
                                label="New Password"
                                name="newPin"
                                autoComplete="off"
                                size={"small"}
                                value={formData.newPin || ""}
                                onChange={handleChange}
                                error={error && error.newPin}
                                disabled={!formData.otp}
                            />
                        </>}
                        <div style={{ display: 'flex' }}>


                            <Button
                                type="submit"
                                fullWidth
                                variant={isSendOtp ? "outlined" : "contained"}
                                sx={{ mt: 3, mb: 2, mr: isSendOtp ? 2 : 0 }}
                                name='sendOtp'
                            // size={"small"}
                            >

                                {isSendOtp ? 'Resend OTP' : 'Send OTP'}
                            </Button>
                            {isSendOtp && <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, }}
                                name='setPassword'
                            // size={"small"}
                            >
                                Set Password
                            </Button>}
                        </div>
                        <Grid container>
                            <Link variant="body2" onClick={() => navigate('/login')} style={{ cursor: 'pointer', textDecoration: 'none' }}>
                                Back to Login
                            </Link>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>

    )
}
