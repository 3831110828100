import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthAPI } from '../../api/AuthApi';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Cookies from "universal-cookie";
import { useDispatch } from "react-redux";
import { loginFailed, loginSuccess, logout } from "../../reducers/AuthSlice";
import { clearState } from "../../reducers/GlobalSettingSlice";
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import WarehouseSelectionModal from "../../utills/WarehouseSelection";
import { setDeliveryDate } from "../../reducers/GlobalSettingSlice";
import moment from "moment";
import { persistor } from './../../store/configureStore';
import AuthTheme from './AuthTheme';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import AlertComponent from "../../utills/AlertComponent";


export default function Login() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cookies = new Cookies();
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isOpenWarehouseModal, setWarehouseOpen] = React.useState(false);
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null)
    const [formData, setFormData] = useState({
        userName: '',
        pin: '',
    });
    const [error, setError] = useState(null);
    const [invalidEmail, setInvalidEmail] = useState(false);

    useEffect(() => {
        if (cookies.get('access_token')) {
            navigate('/');
        } else {
            persistor.purge();
            dispatch(logout());
            dispatch(clearState());
        }
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        if (name === 'userName') setInvalidEmail(false);
    };

    const handleSubmit = async (event) => {
        clearMessage();
        event.preventDefault();
        if (!validateForm()) return;
        try {
            setLoading(true);
            const response = await AuthAPI.login(formData.userName, formData);
            const { data: { data } } = response;
            setUser(data.internalUser);
            setToken(data.jwtToken);
            setLoading(false);
            openWarehouseSelection(true);
        } catch (error) {
            let message = "Something went wrong! Please try again later";
            if (error && error.response && error.response.data && error.response.data.message) {
                message = error.response.data.message;
            }
            setMessage({ message: message, type: 'error' });
            dispatch(loginFailed());
            setLoading(false);
        }
    };

    const validateForm = () => {
        let isValid = true;
        let error = {};
        for (let key in formData) {
            if (!isPropertyValid(key, formData[key])) {
                isValid = false;
                error[key] = true;

            } else {
                error[key] = false;
            }
        }
        setError(error);
        return isValid;
    }

    const isPropertyValid = (key, value) => {
        if (!value.trim()) {
            return false;
        }
        if (key === 'userName') {
            if (!isValidEmail(value)) {
                setInvalidEmail(true);
                return false
            }
        }
        return true;
    }

    const isValidEmail = (email) => {
        // Regular expression for a basic email validation
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(email);
    };

    const clearMessage = () => {
        setMessage(null);
    }

    const openWarehouseSelection = (isOpen) => {
        setWarehouseOpen(isOpen);
        if (!isOpen) {
            dispatch(loginSuccess(user));
            dispatch(setDeliveryDate(moment().add(1, 'days').format('YYYY-MM-DD')));
            cookies.set('access_token', `Bearer ${token}`, { path: '/' });
            const path = location.state ? location.state.prev : "/";
            navigate(path);
        }
    }

    return (
        <ThemeProvider theme={AuthTheme}>
            <CssBaseline />
            <Container component="main" maxWidth="xs" style={{
                display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: "center", height: "100vh"
            }}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress />
                </Backdrop>
                {message && <AlertComponent
                    message={message.message}
                    severity={message.type}
                    onClose={clearMessage}
                />}
                {!user && <Box
                    sx={{
                        boxShadow: 3,
                        borderRadius: 2,
                        px: 4,
                        pt: 4,
                        pb: 6,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: '#ffffff',

                    }}
                >
                    <Typography component="h1" variant="h4" color='primary' align="center">
                        Commex
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="userName"
                            label="Email Address"
                            name="userName"
                            autoComplete="off"
                            autoFocus
                            size={"small"}
                            value={formData.userName}
                            onChange={handleChange}
                            error={error && error.userName}
                            helperText={invalidEmail && "Please enter a valid email"}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="pin"
                            label="Password"
                            type="password"
                            id="pin"
                            autoComplete="new-password"
                            size={"small"}
                            value={formData.pin}
                            onChange={handleChange}
                            error={error && error.pin}

                        />
                        {/* <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    /> */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        // size={"small"}
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link variant="body2" onClick={() => navigate('/forgot-password')} style={{ cursor: 'pointer', textDecoration: 'none' }}>
                                    Forgot password?
                                </Link>
                            </Grid>
                            {/* <Grid item>
                                <Link href="#" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid> */}
                        </Grid>
                    </Box>
                </Box>}
                {user && <WarehouseSelectionModal
                    open={isOpenWarehouseModal}
                    handleClose={() => openWarehouseSelection(false)}
                    parent={'login'}
                    warehouses={user.warehouses}
                />}
            </Container>
        </ThemeProvider>

    );
}



