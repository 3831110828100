import React, { useRef, useState } from "react";
import PageHeader from "../../../components/PageHeader";
import ProductSelector from "../../../utills/ProductSelector";
import { SpeedDial, SpeedDialAction, SpeedDialIcon, FormHelperText, Tooltip, Button } from "@mui/material";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';


export default function RequisitionHeader(props) {
    const { fetchRequisitions, onClickDownloadCsv, handleChange, isDisabled, setSearchQuery, searchQuery } = props;
    const actions = [
        { icon: <CloudDownloadIcon />, name: 'Download', tooltip: 'Download CSV' },
        { icon: <CloudUploadIcon />, name: 'Upload', tooltip: 'Download CSV then update it and upload' },
    ];
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const filesInput = useRef(null);

    const handleChangeSearchInput = (value) => {
        setSearchQuery(value);
    }

    const handleFileupload = (event) => {
        props.handleFileupload(event.target.files[0]);
        filesInput.current.value = '';
    }

    const handleSpeedDialAction = (name) => {
        if (name === 'Download') {
            onClickDownloadCsv();
        }
        if (name === 'Upload') {
            filesInput.current.click();
        }
        handleClose();
    }

    return (
        <PageHeader title='Requisitions'>
            <div style={{ display: 'flex', margin: '0 auto' }}>
                <div style={{ marginBottom: isDisabled ? '5px' : 0 }}>
                    <ProductSelector
                        handleChangeSearchInput={handleChangeSearchInput}
                        searchQuery={searchQuery}
                        search={fetchRequisitions}
                    />
                </div>
                <div style={{ marginLeft: '30px', }}>
                    <Tooltip title={isDisabled ? "Please choose a date from today onwards</FormHelperText" : ""} arrow sx={{ color: 'red' }}>
                        <span>
                            <SpeedDial
                                ariaLabel="SpeedDial basic example"
                                sx={{ position: 'absolute' }}
                                icon={<SpeedDialIcon disabled={isDisabled} />}
                                direction={'down'}
                                style={{ zIndex: 10000, }}
                                disabled={isDisabled}
                                FabProps={{ disabled: isDisabled, size: "small" }}
                                onClose={handleClose}
                                onClick={!open ? handleOpen : handleClose}
                                open={open}
                            >
                                {actions.map((action) => (
                                    <SpeedDialAction
                                        key={action.name}
                                        icon={action.icon}
                                        tooltipTitle={action.tooltip}
                                        onClick={() => handleSpeedDialAction(action.name)}
                                    />
                                ))}

                            </SpeedDial>
                            <input
                                className="csv-input"
                                type="file"
                                ref={filesInput}
                                name="file"
                                placeholder={null}
                                onChange={handleFileupload}
                                accept={".csv"}
                                hidden
                            />
                        </span>

                    </Tooltip>
                    {isDisabled && <FormHelperText error style={{ marginTop: '40px' }}>Please choose a date from today onwards</FormHelperText>}
                </div>
            </div>
        </ PageHeader>
    )
}