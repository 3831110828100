import {
    Button, Typography,
    TextField,
    InputAdornment,
} from "@mui/material";
import React from "react";
import Breadcrumb from "../../../components/BreadCrumb";
import PageHeader from "../../../components/PageHeader";
import SearchIcon from '@mui/icons-material/Search';

export default function VendorSearch(props) {
    const { handleChangeSearchInput } = props;

    return (
        <PageHeader title='Vendors'>
            <TextField
                label="Search"
                onChange={(e) => { handleChangeSearchInput(e.target.value) }}
                style={{ margin: 'auto', width: '500px' }}
                size="small"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
        </PageHeader>

    )
}