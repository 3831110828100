import React, { useState, useEffect } from "react";
import {
    Typography,
    Backdrop, CircularProgress, Alert,
    Grid,
    Modal,
    Box,
    Button,
    IconButton,
    Avatar,
} from "@mui/material";
import { QuotesApi } from "../../../api/QuotesApi";
import { useSelector } from "react-redux";
import QuoteList from "../components/QuotesList";
import ProductSelector from "../../../utills/ProductSelector";
import { IndentApi } from "../../../api/IndentApi";
import IndentList from "../../indents/components/IndentList";
import CancelIcon from '@mui/icons-material/Cancel';
import moment from "moment";
import Breadcrumb from "../../../components/BreadCrumb";
import { useLocation } from 'react-router-dom';
import { debounce } from "lodash";
import PURCHASE_MANAGEMENT_COLORS from './../../../utills/constants';
import PageHeader from "../../../components/PageHeader";
import AlertComponent from "../../../utills/AlertComponent";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    // boxShadow: 24,
    p: 4,
    // borderRadius: '5px'
};

export default function QuoteContainer() {

    const [quotes, SetQuotes] = useState([]);
    const [filteredQuotes, setFilterdQuotes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);

    const { userWarehouse, deliveryDate } = useSelector(x => x.GlobalSettingSlice);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [searchQuery, setSearchQuery] = useState(null);

    const [isOpen, openModal] = useState(false);
    const [indents, setIndents] = useState(null);
    const [isOpenModal, handleModalState] = useState(false);
    const [selectedQuote, setSelectedQuote] = useState(null);
    const isDisabled = moment(deliveryDate).isBefore(moment().format('YYYY-MM-DD'));
    const location = useLocation();

    // Create a new URLSearchParams object from the query string
    const queryParams = new URLSearchParams(location.search);

    // Access specific query parameters
    const requisitionId = queryParams.get('requisitionId');

    useEffect(() => {
        fetchQuotes();
    }, [deliveryDate, userWarehouse, requisitionId]);


    const fetchQuotes = debounce(async () => {

        try {
            setMessage(null);
            setLoading(true);
            const response = await QuotesApi.getQuotes(userWarehouse.id, deliveryDate, requisitionId, searchQuery);
            const { data: { data = [] } } = response;
            SetQuotes(data);
            if (selectedProduct) {
                let quote = data.filter(el => { el.skuId === selectedProduct.id })
                setFilterdQuotes(quote);
            } else {
                setFilterdQuotes(data);
            }
            setLoading(false);
        }
        catch (error) {
            setLoading(false);
            SetQuotes([]);
            setFilterdQuotes([]);
            let message = "Something went wrong! Please try again later";
            if (error && error.response && error.response.data && error.response.data.message) {
                message = error.response.data.message;
            }
            setMessage({ message: message, type: 'error' });
        }
    }, 750);


    const clearMessage = () => {
        setMessage(null);
    }
    const handleChangeProduct = (event, option) => {
        setSelectedProduct(option);
        setSearchQuery(option.familyName);
        fetchQuotes();

    }

    const handleChangeSearchInput = (value) => {
        console.log(value);
        setSearchQuery(value);
    }

    const handleCloseModal = () => {
        openModal(false);
        handleModalState(false);
        setIndents(null);
    }

    return (
        <>
            <PageHeader title='Quotes'>
                <div style={{ display: 'flex', margin: '0 auto' }}>
                    <ProductSelector
                        handleChangeSearchInput={handleChangeSearchInput}
                        searchQuery={searchQuery}
                        search={fetchQuotes}
                    />
                </div>
            </ PageHeader>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress />
            </Backdrop>

            {message && <AlertComponent
                message={message.message}
                severity={message.type}
                onClose={clearMessage}
            />}

            <QuoteList
                data={filteredQuotes}
                selectedProduct={selectedProduct}
                // showIndent={showIndents}
                isDisabled={isDisabled}
                setLoading={setLoading}
                setErrorMessage={setMessage}
                onRefresh={fetchQuotes}
            />


        </>
    )

}