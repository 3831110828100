
import { createTheme } from '@mui/material/styles';

const AuthTheme = createTheme({
    palette: {
        background: {
            default: '#000000'//black
        },
        primary: {
            main: '#7753a4',
        },
        
    }

});


export default AuthTheme;