import React from "react";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

export default function ProductSelector(props) {

    return (
        <>
            <TextField
                label="Search"
                onChange={(e) => { props.handleChangeSearchInput(e.target.value); props.search() }}
                style={{ margin: 'auto', width: '500px' }}
                size="small"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
        </>
    )
}