import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    TableHead,
    Grid,
    Button
} from "@mui/material";
import moment from "moment";
import EditIcon from '@mui/icons-material/Edit';

const columns = [
    { id: 'id', label: 'Id' },
    { id: 'name', label: 'Name', },
    { id: 'email', label: 'Email', },
    {
        id: 'timeCreated',
        label: 'Created On',
        format: (value) => moment(value).format('ll'),
    },
    {
        id: 'timeUpdated',
        label: 'Updated On',
        format: (value) => moment(value).format('ll'),
    },
    {
        id: 'mobile',
        label: 'Mobile',
    },
    {
        id: 'active',
        label: 'Status',
        render: (value) => <div style={{
            width: 'fit-content',
            color: value ? '#006644' : '#BF2600',
            backgroundColor: value ? '#E3FCEF' : '#FFEBE6',
            textTransform: 'uppercase',
            fontSize: '11px',
            fontWeight: '700',
            padding: '2px 4px 3px 4px',
            fontFamily: 'sans-serif'
        }} > {value ? 'Active' : 'Inactive'}</div >
    },
    {
        id: 'action',
        label: 'Action',
        render: () => <div>
            <Button variant="outlined" size="small" style={{ minWidth: '45px' }}>
                <EditIcon />
            </Button>
        </div>
    }
];


export default function UserList(props) {
    const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, data } = props;

    return (
        <>
            <Grid>
                <TableContainer sx={{ maxHeight: 500, pr: '10px', pl: '10px' }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        // align={column.align}
                                        style={{ color: '#7753a4', fontWeight: 'bold' }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                            {columns.map((column) => {
                                                const value = column.id === 'action' ? row : row[column.id];
                                                return (
                                                    <TableCell key={column.id}
                                                    // align={column.align}
                                                    >
                                                        {column.format && value
                                                            ? column.format(value)
                                                            : column.render
                                                                ? column.render(value)
                                                                : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )

}