import { ApiService } from "./NetworkHandler";
const baseURL = '/api/v1/indents';

export const IndentApi = {
    getAllVendorQuotes: function (vendorId, deliveryDate) {
        return ApiService.sendRequest('get', `${baseURL}/get-all-quotes-to-buy?vendorId=${vendorId}&deliveryDate=${deliveryDate}`);
    },
    getAllIndents: function (quoteId, deliveryDate, warehouseId) {
        return ApiService.sendRequest('get', `${baseURL}/get-all-quotes-to-buy?quoteId=${quoteId}&deliveryDate=${deliveryDate}&warehouseId=${warehouseId}`);
    },
    approveOrReject: function (data) {
        return ApiService.sendRequest('post', `${baseURL}/approve-or-reject`, data);
    },
    getIndents: function (warehouseId, deliveryDate, quoteId = '', search = '') {
        let url = `${baseURL}/get-all?warehouseId=${warehouseId}&deliveryDate=${deliveryDate}`
        if (quoteId) {
            url = `${url}&quoteId=${quoteId}`;
        }
        if (search) {
            url = `${url}&search=${search}`;
        }
        return ApiService.sendRequest('get', url);
    },
    closeIndent: function (indentId) {
        return ApiService.sendRequest('post', `${baseURL}/close?id=${indentId}`);
    }
}