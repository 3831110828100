import React, { useState } from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import Box from '@mui/material/Box';
import { ListItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import TagIcon from '@mui/icons-material/Tag';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigationHistory } from '../contexts/NavigationHistoryContext';

const items = [
    {
        item: 'User Management',
        links: [
            {
                path: '/users',
                label: 'Users',
            },
            {
                path: '/users-groups',
                label: 'User Groups',
            },
        ]
    },
    {
        item: 'Vendor Management',
        links: [
            {
                path: '/vendors',
                label: 'Vendors',
            },
        ]
    },
    {
        item: 'Agent Management',
        links: [
            {
                path: '/agents',
                label: 'Agents',
            },
        ]
    },
    {
        item: 'Purchase Management',
        links: [
            {
                path: '/requisitions',
                label: 'Requisitions',
            },
            {
                path: '/quotes',
                label: 'Quotes',
            },
            {
                path: '/indents',
                label: 'Indents',
            },
            {
                path: '/purchase-confirmations',
                label: 'Purchase Confirmations',
            },
            {
                path: '/shipments',
                label: 'Shipments',
            },

        ]
    }];

export default function SideBarMenu(props) {
    const [open, setOpen] = useState(true);
    const [selectedIndex, setIndex] = useState(null);
    const navigate = useNavigate();
    const { clearHistory } = useNavigationHistory();

    const handleClick = (index, state = null) => {
        setIndex(index);
        if (index === selectedIndex) {
            if (!state) {
                setOpen(!open);
            } else {
                setOpen(state);
            }
        } else {
            setOpen(true);
        }
    };

    return (
        <Box
            sx={{ width: 300 }}
            role="presentation"
        >
            <List>
                <div style={{ textAlign: "right", marginRight: '10px' }}>
                    <IconButton onClick={props.onClose}>
                        <ClearIcon />
                    </IconButton>
                </div>
                <ListItemButton onClick={() => { navigate("/"); props.onClose() }}>
                    <ListItemIcon>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary='Home' />
                </ListItemButton>

                {items.map((item, index) => {
                    return (<>
                        <ListItemButton onClick={() => handleClick(index)} onMouseEnter={() => handleClick(index, true)} onMouseLeave={(e) => e.preventDefault()}>
                            <ListItemIcon>
                                <MenuIcon />
                            </ListItemIcon>
                            <ListItemText primary={item.item} />
                            {open && index === selectedIndex ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={open && index === selectedIndex} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {item.links.map(el => {
                                    return (<ListItemButton sx={{ pl: 4 }}>
                                        <ListItemIcon>
                                            <TagIcon fontSize='small' />
                                        </ListItemIcon>
                                        <ListItemText primary={el.label} onClick={() => { navigate(el.path); clearHistory(); props.onClose() }} />
                                    </ListItemButton>
                                    )
                                })}
                            </List>
                        </Collapse>
                    </>
                    )
                })}
            </List>
        </Box>
    );
}