import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userWarehouse: null,
    deliveryDate: null,
}

export const GlobalSettingSlice = createSlice({
    name: 'UserDetails',
    initialState,
    reducers: {
        setUserWarehouse: (state, action) => {
            state.userWarehouse = action.payload;
        },
        setDeliveryDate: (state, action) => {
            state.deliveryDate = action.payload;
        },
        clearState: (state) => {
            return initialState;
        }
    }
});

export const { setUserWarehouse, clearState, setDeliveryDate } = GlobalSettingSlice.actions;
export default GlobalSettingSlice.reducer;