import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from "react-router-dom";
import Root from './components/Root';
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './store/configureStore';
import { Provider } from 'react-redux';
import history from './components/CustomHistory';
import { NavigationHistoryProvider } from './contexts/NavigationHistoryContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter history={history}>
        <NavigationHistoryProvider>
          <Root />
        </NavigationHistoryProvider>
      </BrowserRouter>

    </PersistGate>
  </Provider>
);

